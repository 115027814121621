import { setToLocalStorage } from "@/utilities/localStorage"
import { useEffect } from "react"
import { Route, Routes } from "react-router-dom"
import HealthcareSignUp from "./HealthcareSignUp"
import HealthcareHome from "./HealthcareHome"
import HealthcareLogIn from "./HealthcareLogIn"
import InitiateForgotPassword from "./HealthcareForgotPassword"
import ResetPassword from "./HealthcareResetPassword"
import HealthcareRequestCompleteSignUp from "../components/auth/HealthcareRequestCompleteSignup"
import HealthcareInviteSignup from "../components/auth/HealthcareInviteSignup"
import RouteMetadata from "@/components/RouteMetadata"
import useSetAmplitudeUserId from "@/hooks/useSetAmplitudeUserId"

export default function HealthcareWrapper() {
  useEffect(() => {
    setToLocalStorage("tenantId", "healthcare")
  }, [])

  useSetAmplitudeUserId()

  return (
    <main className="p-10">
      <Routes>
        <Route
          path="/auth/request-complete-signup"
          element={
            <RouteMetadata title="Complete Sign Up">
              <HealthcareRequestCompleteSignUp />
            </RouteMetadata>
          }
        />
        <Route
          path="/auth"
          element={
            <RouteMetadata title="Login">
              <HealthcareLogIn />
            </RouteMetadata>
          }
        />
        <Route
          path="/auth/signup/*"
          element={
            <RouteMetadata title="Sign Up">
              <HealthcareSignUp />
            </RouteMetadata>
          }
        />
        <Route
          path="/auth/forgot-password"
          element={
            <RouteMetadata title="Forgot Password">
              <InitiateForgotPassword />
            </RouteMetadata>
          }
        />

        <Route
          path="/auth/reset-password"
          element={
            <RouteMetadata title="Reset Password">
              <ResetPassword />
            </RouteMetadata>
          }
        />
        <Route
          path="/auth/invite/:id"
          element={
            <RouteMetadata title="Invite Signup">
              <HealthcareInviteSignup />
            </RouteMetadata>
          }
        />
        <Route
          path="/*"
          element={
            <RouteMetadata title="Home">
              <HealthcareHome />
            </RouteMetadata>
          }
        />
      </Routes>
    </main>
  )
}
