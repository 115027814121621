import { Link, useNavigate, useParams } from "react-router-dom"
import GuarantorAuthTitle from "../components/GuarantorAuthTitle"
import { useState } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { signUp } from "supertokens-auth-react/recipe/emailpassword"
import { sendVerificationEmail } from "supertokens-auth-react/recipe/emailverification"
import { useToast } from "@/hooks/useToast"
import { useForm } from "react-hook-form"
import FormGroupInput from "@/components/form/FormGroupInput"
import { Button } from "@/components/Button"
import axios from "axios"
import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import { Checkbox } from "@/components/Checkbox"
import PasswordRequirements from "@/components/PasswordRequirements"

export const getGuarantorInviteSignUpQueryKey = "getGuarantorInviteSignUp"

type Inputs = {
  guarantorEmail: string
  guarantorPhoneNumber: string
  guarantorFirstName: string
  guarantorLastName: string
  guarantorPassword: string
  guarantorConfirmPassword: string
}
export default function GuarantorInviteSignUp() {
  const navigate = useNavigate()
  const id = useParams().id

  const { toast } = useToast()

  const [showCheckEmail, setShowCheckEmail] = useState(false)
  const [hasAgreedToPrivacyPolicy, setHasAgreedToPrivacyPolicy] =
    useState(false)

  const query = useQuery({
    queryKey: [getGuarantorInviteSignUpQueryKey],
    queryFn: async () => {
      if (!id) {
        return null
      }

      const response = await axios.get(
        `${import.meta.env.VITE_SUPERTOKENS_API_DOMAIN}/guarantors/invite/${id}`
      )

      return response.data
    },
  })

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>()

  const mutation = useMutation({
    mutationFn: async (data: Inputs) => {
      const response = await signUp({
        formFields: [
          {
            id: "email",
            value: data.guarantorEmail,
          },
          {
            id: "phoneNumber",
            value: data.guarantorPhoneNumber,
          },
          {
            id: "password",
            value: data.guarantorPassword,
          },
          {
            id: "firstName",
            value: data.guarantorFirstName,
          },
          {
            id: "lastName",
            value: data.guarantorLastName,
          },
        ],
      })

      if (response.status === "FIELD_ERROR") {
        // one of the input formFields failed validation
        throw new Error(response.formFields[0].error)
      } else if (response.status === "SIGN_UP_NOT_ALLOWED") {
        // the reason string is a user friendly message
        // about what went wrong. It can also contain a support code which users
        // can tell you so you know why their sign up was not allowed.
        throw new Error(response.reason)
      }

      //send verification email
      const verificationResponse = await sendVerificationEmail()
      if (verificationResponse.status === "EMAIL_ALREADY_VERIFIED_ERROR") {
        // This can happen if the info about email verification in the session was outdated.
        // Redirect the user to the home page
        navigate("/guarantors")
      } else {
        // email was sent successfully.
        setShowCheckEmail(true)
      }
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
    },
  })

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    const error = query.error as any
    const message = error.response?.data?.message

    return <ErrorBlock message={message} />
  }

  if (showCheckEmail) {
    return <CheckEmail />
  }

  const { email, firstName, lastName, phoneNumber, status } = query.data

  if (status === "ACCEPTED") {
    return (
      <div className="flex flex-col gap-5 text-center max-w-[500px] mx-auto ">
        <GuarantorAuthTitle>Invite Already Accepted</GuarantorAuthTitle>
        <p>Please log into your account instead.</p>

        <Link to="/guarantors/auth/login">
          <Button className="w-full" role="link">
            Sign In
          </Button>
        </Link>
      </div>
    )
  }

  if (status === "RESCINDED") {
    return (
      <div className="flex flex-col gap-5 text-center max-w-[500px] mx-auto ">
        <GuarantorAuthTitle>Invite Rescinded</GuarantorAuthTitle>
        <p>Please ask the patient to invite you again.</p>
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-5 text-center max-w-[500px] mx-auto ">
      <GuarantorAuthTitle>Guarantor Registration</GuarantorAuthTitle>
      <p className=" text-sm">
        {" "}
        Fill in your details to register as a guarantor
      </p>

      <p className="font-medium">
        Already have an account? <Link to="/guarantors/auth/login">Log in</Link>
      </p>

      <form
        className="grid sm:grid-cols-2  gap-7  mt-5"
        onSubmit={handleSubmit(async (data) => {
          await mutation.mutateAsync(data)
        })}
      >
        <FormGroupInput
          id="guarantorEmail"
          label="Email"
          type="email"
          placeholder="Enter your email address"
          readonly={true}
          register={register("guarantorEmail", {
            value: email,
          })}
          error={errors.guarantorEmail?.message}
        />
        <FormGroupInput
          id="guarantorPhoneNumber"
          label="Phone Number"
          type="tel"
          placeholder="Enter your phone number"
          readonly={true}
          register={register("guarantorPhoneNumber", {
            value: phoneNumber,
          })}
          error={errors.guarantorPhoneNumber?.message}
        />
        <FormGroupInput
          id="guarantorFirstName"
          label="First Name"
          type="text"
          placeholder="Enter your first name"
          register={register("guarantorFirstName", {
            value: firstName,
            required: {
              value: true,
              message: "Please enter your first name",
            },
          })}
          error={errors.guarantorFirstName?.message}
        />

        <FormGroupInput
          id="guarantorLastName"
          label="Last Name"
          type="text"
          placeholder="Enter your last name"
          register={register("guarantorLastName", {
            value: lastName,
            required: {
              value: true,
              message: "Please enter your last name",
            },
          })}
          error={errors.guarantorLastName?.message}
        />

        <div>
          <FormGroupInput
            id="guarantorPassword"
            label="Password"
            type="password"
            placeholder="Enter your password"
            register={register("guarantorPassword", {
              required: {
                value: true,
                message: "Please enter your password",
              },
              minLength: {
                value: 6,
                message: "Password must be at least 6 characters",
              },
            })}
            error={errors.guarantorPassword?.message}
          />
          <PasswordRequirements />
        </div>

        <FormGroupInput
          id="guarantorConfirmPassword"
          label="Confirm Password"
          type="password"
          placeholder="Confirm your password"
          register={register("guarantorConfirmPassword", {
            required: {
              value: true,
              message: "Please confirm your password",
            },
            validate: (value) => {
              if (value !== watch("guarantorPassword")) {
                return "Passwords do not match"
              }
            },
          })}
          error={errors.guarantorConfirmPassword?.message}
        />

        <div className="flex space-x-2 sm:col-span-2">
          <Checkbox
            id="has agreed to privacy policy"
            checked={hasAgreedToPrivacyPolicy}
            onCheckedChange={() =>
              setHasAgreedToPrivacyPolicy(!hasAgreedToPrivacyPolicy)
            }
            className="mt-1"
          />
          <label
            htmlFor="terms"
            className="text-sm text-gray-600 leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            By ticking this box, you confirm that you have read and agree to our{" "}
            <Link to="/privacy-policy">Privacy Policy</Link>
          </label>
        </div>

        <Button
          className="sm:col-span-2"
          isLoading={mutation.isPending}
          disabled={mutation.isPending || !hasAgreedToPrivacyPolicy}
        >
          Submit
        </Button>
      </form>
    </div>
  )
}

function CheckEmail() {
  return (
    <div className="flex flex-col gap-5 text-center items-center">
      <h1 className="text-3xl text-black font-medium">Email Verification</h1>

      <p className="max-w-[55ch] text-sm">
        An email has been sent to your email address.
      </p>

      <p>
        <b>Click on the link to complete the sign up process.</b>
      </p>
    </div>
  )
}
