import { useHealthcareUserStore } from "../stores/healthcareUserStore"

export default function ProtectedResource({
  children,
  allowedRoles,
}: {
  children: React.ReactNode
  allowedRoles: string[]
}) {
  const user = useHealthcareUserStore((state: any) => state.user)

  if (!user || !allowedRoles.includes(user.role)) {
    return <></>
  }

  return children
}
