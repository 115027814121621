import { ShieldCheck } from "lucide-react"
import { Link } from "react-router-dom"

export default function HealthcareSettingsNavigation() {
  return (
    <div className="px-6 py-10 max-w-screen-xl mx-auto">
      <header className="mb-8">
        <h1 className="text-2xl font-bold">Settings</h1>
        <p className="text-gray-600">Manage your settings</p>
        <hr className="border-t border-gray-300 mt-4" />
      </header>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <SettingsSection
          title="Profile Management"
          description="Set up your care facility"
          links={[
            { text: "My Profile  ›", to: "my-profile" },
            {
              text: "Care Provider Profile   › ",
              to: "care-provider-profile",
            },
            { text: "Team Access  ›", to: "manage-users" },
          ]}
        />

        <SettingsSection
          title="Security"
          description="Secure your account"
          links={[{ text: "Reset Password   ›", to: "reset-password" }]}
        />

        <SettingsSection
          title="Payments"
          description="Set up how you receive payment"
          links={[{ text: "Payment Settings  ›", to: "billing" }]}
        />
      </div>
    </div>
  )
}

function SettingsSection({
  title,
  description,
  links,
}: {
  title: string
  description: string
  links: { text: string; to: string }[]
}) {
  return (
    <div className=" flex gap-1">
      <div>
        <ShieldCheck className=" mt-2 text-gray-600 h-4 w-4" />
      </div>

      <div>
        <h2 className=" font-normal text-lg">{title}</h2>
        <p className="text-gray-500 text-sm">{description}</p>
        <ul className="mt-4 space-y-2 text-black">
          {links.map((link, index) => (
            <li key={index}>
              <Link
                to={link.to}
                className="flex items-center justify-between text-black font-normal hover:underline no-underline"
              >
                {link.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
