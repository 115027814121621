export default function Logo({
  fill = "#b325ff",
  width = "500",
  height = "250",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      zoomAndPan="magnify"
      viewBox="0 0 375 187.499995"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <g />
      </defs>
      <g fill={fill} fillOpacity="1">
        <g transform="translate(25.182849, 130.317003)">
          <g>
            <path d="M 10.921875 -113.359375 C 13.859375 -116.296875 17.359375 -117.765625 21.421875 -117.765625 C 25.492188 -117.765625 28.992188 -116.296875 31.921875 -113.359375 C 34.859375 -110.429688 36.328125 -106.929688 36.328125 -102.859375 C 36.328125 -98.796875 34.859375 -95.296875 31.921875 -92.359375 C 28.992188 -89.429688 25.492188 -87.96875 21.421875 -87.96875 C 17.359375 -87.96875 13.859375 -89.429688 10.921875 -92.359375 C 7.992188 -95.296875 6.53125 -98.796875 6.53125 -102.859375 C 6.53125 -106.929688 7.992188 -110.429688 10.921875 -113.359375 Z M 8.515625 -72.796875 L 8.515625 6.390625 C 8.515625 13.003906 6.195312 16.3125 1.5625 16.3125 C 0.613281 16.3125 -0.378906 16.144531 -1.421875 15.8125 C -2.460938 15.488281 -3.3125 15.132812 -3.96875 14.75 L -4.828125 14.1875 L -12.625 34.484375 C -12.4375 34.578125 -11.820312 34.859375 -10.78125 35.328125 C -9.738281 35.804688 -8.957031 36.160156 -8.4375 36.390625 C -7.914062 36.628906 -7.039062 36.9375 -5.8125 37.3125 C -4.582031 37.695312 -3.398438 37.984375 -2.265625 38.171875 C -1.128906 38.359375 0.285156 38.546875 1.984375 38.734375 C 3.691406 38.921875 5.488281 39.015625 7.375 39.015625 C 12.101562 39.015625 16.265625 38.1875 19.859375 36.53125 C 23.453125 34.875 26.195312 32.648438 28.09375 29.859375 C 29.988281 27.078125 31.382812 24.144531 32.28125 21.0625 C 33.175781 17.988281 33.625 14.703125 33.625 11.203125 L 33.625 -72.796875 Z M 8.515625 -72.796875 " />
          </g>
        </g>
      </g>
      <g fill={fill} fillOpacity="1">
        <g transform="translate(60.228681, 130.317003)">
          <g>
            <path d="M 10.921875 -113.359375 C 13.859375 -116.296875 17.359375 -117.765625 21.421875 -117.765625 C 25.492188 -117.765625 28.992188 -116.296875 31.921875 -113.359375 C 34.859375 -110.429688 36.328125 -106.929688 36.328125 -102.859375 C 36.328125 -98.796875 34.859375 -95.296875 31.921875 -92.359375 C 28.992188 -89.429688 25.492188 -87.96875 21.421875 -87.96875 C 17.359375 -87.96875 13.859375 -89.429688 10.921875 -92.359375 C 7.992188 -95.296875 6.53125 -98.796875 6.53125 -102.859375 C 6.53125 -106.929688 7.992188 -110.429688 10.921875 -113.359375 Z M 8.515625 -72.796875 L 8.515625 0 L 33.625 0 L 33.625 -72.796875 Z M 8.515625 -72.796875 " />
          </g>
        </g>
      </g>
      <g fill={fill} fillOpacity="1">
        <g transform="translate(95.983951, 130.317003)">
          <g>
            <path d="M 8.515625 0 L 8.515625 -72.796875 L 33.625 -72.796875 L 33.625 -62.296875 L 33.90625 -62.296875 C 34.101562 -62.671875 34.410156 -63.140625 34.828125 -63.703125 C 35.253906 -64.273438 36.175781 -65.269531 37.59375 -66.6875 C 39.019531 -68.101562 40.535156 -69.378906 42.140625 -70.515625 C 43.742188 -71.648438 45.820312 -72.664062 48.375 -73.5625 C 50.9375 -74.46875 53.539062 -74.921875 56.1875 -74.921875 C 58.925781 -74.921875 61.617188 -74.539062 64.265625 -73.78125 C 66.921875 -73.019531 68.863281 -72.265625 70.09375 -71.515625 L 72.078125 -70.375 L 61.578125 -49.09375 C 58.453125 -51.738281 54.101562 -53.0625 48.53125 -53.0625 C 45.5 -53.0625 42.894531 -52.398438 40.71875 -51.078125 C 38.539062 -49.753906 37.003906 -48.144531 36.109375 -46.25 C 35.210938 -44.363281 34.570312 -42.757812 34.1875 -41.4375 C 33.8125 -40.113281 33.625 -39.070312 33.625 -38.3125 L 33.625 0 Z M 8.515625 0 " />
          </g>
        </g>
      </g>
      <g fill={fill} fillOpacity="1">
        <g transform="translate(159.549116, 130.317003)">
          <g>
            <path d="M 82.15625 -32.921875 L 29.375 -32.921875 C 29.375 -27.804688 31.023438 -24.019531 34.328125 -21.5625 C 37.640625 -19.101562 41.238281 -17.875 45.125 -17.875 C 49.1875 -17.875 52.398438 -18.414062 54.765625 -19.5 C 57.128906 -20.59375 59.828125 -22.75 62.859375 -25.96875 L 81.015625 -16.890625 C 73.453125 -4.210938 60.875 2.125 43.28125 2.125 C 32.300781 2.125 22.882812 -1.632812 15.03125 -9.15625 C 7.1875 -16.675781 3.265625 -25.734375 3.265625 -36.328125 C 3.265625 -46.921875 7.1875 -56 15.03125 -63.5625 C 22.882812 -71.132812 32.300781 -74.921875 43.28125 -74.921875 C 54.820312 -74.921875 64.207031 -71.582031 71.4375 -64.90625 C 78.675781 -58.238281 82.296875 -48.710938 82.296875 -36.328125 C 82.296875 -34.617188 82.25 -33.484375 82.15625 -32.921875 Z M 30.078125 -46.828125 L 57.75 -46.828125 C 57.175781 -50.609375 55.679688 -53.515625 53.265625 -55.546875 C 50.859375 -57.578125 47.765625 -58.59375 43.984375 -58.59375 C 39.828125 -58.59375 36.515625 -57.503906 34.046875 -55.328125 C 31.585938 -53.160156 30.265625 -50.328125 30.078125 -46.828125 Z M 30.078125 -46.828125 " />
          </g>
        </g>
      </g>
      <g fill={fill} fillOpacity="1">
        <g transform="translate(238.012444, 130.317003)">
          <g>
            <path d="M 8.515625 0 L 8.515625 -122.03125 L 33.625 -122.03125 L 33.625 -65.125 L 33.90625 -65.125 C 40.4375 -71.65625 47.390625 -74.921875 54.765625 -74.921875 C 58.359375 -74.921875 61.925781 -74.445312 65.46875 -73.5 C 69.019531 -72.550781 72.425781 -71.082031 75.6875 -69.09375 C 78.957031 -67.113281 81.609375 -64.300781 83.640625 -60.65625 C 85.671875 -57.019531 86.6875 -52.785156 86.6875 -47.953125 L 86.6875 0 L 61.578125 0 L 61.578125 -41.140625 C 61.578125 -44.929688 60.367188 -48.242188 57.953125 -51.078125 C 55.546875 -53.921875 52.40625 -55.34375 48.53125 -55.34375 C 44.738281 -55.34375 41.304688 -53.875 38.234375 -50.9375 C 35.160156 -48.007812 33.625 -44.742188 33.625 -41.140625 L 33.625 0 Z M 8.515625 0 " />
          </g>
        </g>
      </g>
      <g fill={fill} fillOpacity="1">
        <g transform="translate(324.705223, 130.317003)">
          <g>
            <path d="M 24.6875 -30.359375 C 29.226562 -30.359375 33.082031 -28.773438 36.25 -25.609375 C 39.414062 -22.441406 41 -18.585938 41 -14.046875 C 41 -9.597656 39.414062 -5.789062 36.25 -2.625 C 33.082031 0.539062 29.226562 2.125 24.6875 2.125 C 20.238281 2.125 16.429688 0.539062 13.265625 -2.625 C 10.097656 -5.789062 8.515625 -9.597656 8.515625 -14.046875 C 8.515625 -18.585938 10.097656 -22.441406 13.265625 -25.609375 C 16.429688 -28.773438 20.238281 -30.359375 24.6875 -30.359375 Z M 24.6875 -30.359375 " />
          </g>
        </g>
      </g>
    </svg>
  )
}
