import { Button, ButtonProps } from "@/components/Button"
import { MoveRight } from "lucide-react"

export default function ArrowButton({ children, ...props }: ButtonProps) {
  return (
    <Button
      className="rounded-none mx-auto disabled:bg-gray-500  shadow-sm shadow-gray-500"
      {...props}
    >
      <div className="flex items-center gap-2">
        {children} <MoveRight className="w-5 h-5" />
      </div>
    </Button>
  )
}
