import { useQuery } from "@tanstack/react-query"
import Session from "supertokens-web-js/recipe/session"
import { verifyEmail } from "supertokens-auth-react/recipe/emailverification"
import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import { Link, useNavigate } from "react-router-dom"
import { Button } from "@/components/Button"
export const verifyGuarantorEmailKey = "verifyGuarantorEmail"
export default function GuarantorVerifyEmail() {
  const navigate = useNavigate()

  const verificationQuery = useQuery({
    queryKey: [verifyGuarantorEmailKey],
    queryFn: async () => {
      const doesSessionExist = await Session.doesSessionExist()
      if (!doesSessionExist) {
        throw new Error(
          "Something went wrong and we could not verify your email. Please try again later."
        )
      }

      const response = await verifyEmail()
      if (response.status === "EMAIL_VERIFICATION_INVALID_TOKEN_ERROR") {
        // This can happen if the verification code is expired or invalid.
        // You should ask the user to retry
        throw new Error(
          "Oops! Seems like the verification link expired. Please try again"
        )
      }

      //Check if email has already been verified. If it has, redirect to next step.
      const validationErrors = await Session.validateClaims()

      if (validationErrors.length === 0) {
        navigate("/guarantors")
      }

      return true
    },
  })

  if (verificationQuery.isLoading) {
    return <LoadingPage />
  }
  if (verificationQuery.isError) {
    return <ErrorBlock message={verificationQuery.error.message} />
  }

  return (
    <>
      <div className="flex flex-col gap-5 text-center items-center">
        Your email has been verified successfully.
        <Link to="/guarantors/onboarding/personal-info">
          <Button>Continue With Sign Up</Button>
        </Link>
      </div>
    </>
  )
}
