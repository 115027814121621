import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Table"
import Title from "../typography/Title"
import LoadingPage from "@/Routes/LoadingPage"
import { useMutation, useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useState } from "react"
import { Button } from "@/components/Button"
import Pagination from "@/components/Pagination"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/Dialog"
import { useForm } from "react-hook-form"
import FormGroupInput from "@/components/form/FormGroupInput"
import { useToast } from "@/hooks/useToast"
import ErrorBlock from "@/components/ErrorBlock"
import { formatDate } from "@/utilities/dateUtilities"
import DialogTriggerButton from "@/components/DialogTriggerButton"
import { Check, CheckCheck, Ellipsis, MessageCircleMore, X } from "lucide-react"

const queryKey = "patientLoanRequests"

type Inputs = {
  department: string
  nameOfDoctor: string
  treatmentType: string
  plannedTreatmentDate: string
  description: string
  additionalComments: string
  medicalFiles: FileList
  billDetailFile: FileList
}

export default function PatientRequestMedicalInfo() {
  const [dialogOpen, setDialogOpen] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>()

  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  })

  const [activeRequest, setActiveRequest] = useState<any>(null)
  const query = useQuery({
    queryKey: [queryKey, pagination],
    queryFn: async () => {
      const result = await axios.get(
        import.meta.env.VITE_API_BASE_URL +
          `/healthcare/patient-healthcare-requests?page=${pagination.page}&limit=${pagination.limit}`
      )

      return result.data
    },
  })

  function onPageChange(page: number) {
    setPagination({ page, limit: pagination.limit })
  }

  const { toast } = useToast()

  const submitMutation = useMutation({
    mutationFn: async ({
      requestId,
      data,
    }: {
      requestId: number
      data: Inputs
    }) => {
      const formData = new FormData()
      formData.append("requestId", requestId.toString())
      formData.append("department", data.department)
      formData.append("nameOfDoctor", data.nameOfDoctor)
      formData.append("treatmentType", data.treatmentType)
      formData.append("plannedTreatmentDate", data.plannedTreatmentDate)
      formData.append("description", data.description)
      formData.append("additionalComments", data.additionalComments)
      formData.append("billDetailFile", data.billDetailFile[0])
      for (let i = 0; i < data.medicalFiles.length; i++) {
        formData.append("medicalFiles", data.medicalFiles[i]) // Append each file separately
      }

      const result = await axios.post(
        import.meta.env.VITE_API_BASE_URL +
          `/healthcare/patient-medical-info-request`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )

      return result.data
    },
    onSuccess: (result) => {
      query.refetch()
      toast({
        title: "Success",
        description: result.message,
      })
      setDialogOpen(false)
      reset()
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
      query.refetch()
    },
  })

  const rejectMutation = useMutation({
    mutationFn: async (requestId: number) => {
      const result = await axios.post(
        import.meta.env.VITE_API_BASE_URL +
          `/healthcare/reject-medical-info-request`,
        {
          requestId: requestId,
        }
      )

      return result.data
    },
    onSuccess: (result) => {
      query.refetch()
      setDialogOpen(false)
      toast({
        title: "Success",
        description: result.message,
      })
      reset()
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
      query.refetch()
    },
  })

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    return (
      <ErrorBlock message="There was an error loading the patient loan requests" />
    )
  }

  const { healthcareRequests } = query.data

  return (
    <div>
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <Title>Patient Requests</Title>

        <Table className="mt-5">
          <TableHeader>
            <TableRow className="bg-gray-200 ">
              <TableHead>Patient Name</TableHead>
              <TableHead>Department</TableHead>
              <TableHead>Doctor Assigned</TableHead>
              <TableHead>Consent Status</TableHead>
              <TableHead>Loan Status</TableHead>
              <TableHead>Date Created</TableHead>
              <TableHead className="w-44">Actions</TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {healthcareRequests.map((request: any) => (
              <TableRow key={request.id}>
                <TableCell className="font-medium">
                  <div className="flex items-center gap-x-6">
                    <div
                      className="rounded-full  min-w-16 min-h-16 grid place-content-center text-2xl border-[6px] border-white bg-primary text-white"
                      aria-hidden="true"
                    >
                      {request.patientFirstName[0]}
                    </div>
                    <div>
                      <h3 className="text-base/7   text-gray-900">
                        {request.patientFirstName +
                          " " +
                          request.patientLastName}
                      </h3>
                      <p className="text-sm/6 font-normal">
                        D.O.B: {formatDate(request.dateOfBirth)}
                      </p>
                    </div>
                  </div>
                </TableCell>
                <TableCell>{request.department}</TableCell>
                <TableCell>{request.assignedDocter}</TableCell>
                <TableCell className="underline font-medium">
                  {request.consentStatus ? "Approved" : "Pending"}
                </TableCell>
                <TableCell>
                  {request.status === "PENDING" && (
                    <div className="flex items-center gap-2">
                      <div>
                        <Ellipsis />
                      </div>
                      <div>{request.status}</div>
                    </div>
                  )}
                  {request.status === "APPROVED" && (
                    <div className="flex items-center gap-2 text-green-500">
                      <div>
                        <Check />
                      </div>
                      <div>{request.status}</div>
                    </div>
                  )}
                  {request.status === "REJECTED" && (
                    <div className="flex items-center gap-2 text-red-600">
                      <div>
                        <X />
                      </div>
                      <div>{request.status}</div>
                    </div>
                  )}
                  {request.status === "DISBURSED" && (
                    <div className="flex items-center gap-2 text-green-700">
                      <div>
                        <CheckCheck />
                      </div>
                      <div>{request.status}</div>
                    </div>
                  )}
                  {request.status === "IN_REVIEW" && (
                    <div className="flex items-center gap-2 text-yellow-400">
                      <div>
                        <MessageCircleMore />
                      </div>
                      <div>{request.status}</div>
                    </div>
                  )}
                </TableCell>
                <TableCell>{formatDate(request.createdAt)}</TableCell>
                <TableCell>
                  <DialogTriggerButton
                    onClick={() => setActiveRequest(request)}
                    disabled={request.status !== "PENDING"}
                  >
                    Approve Request
                  </DialogTriggerButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
          currentPage={pagination.page}
          totalEntries={query.data.total}
          entriesPerPage={pagination.limit}
          onPageChange={onPageChange}
        />

        <DialogContent className="sm:max-w-[800px]">
          <DialogHeader>
            <DialogTitle>Create Medical Information Report</DialogTitle>
            <DialogDescription>
              Please submit the below medical and treatment information on
              behalf of your patient. This step is a requirement in order for
              Jireh to fund the treatment.
            </DialogDescription>
          </DialogHeader>

          {activeRequest && (
            <form>
              <div className="py-4 flex items-center justify-between">
                <div className="text-sm">
                  <h2 className="text-xl font-medium">
                    {activeRequest.patientFirstName +
                      " " +
                      activeRequest.patientLastName}
                  </h2>
                  <p>DOB: {formatDate(activeRequest.dateOfBirth)}</p>
                  <p>ID Number: {activeRequest.idNumber}</p>
                </div>

                <p>{activeRequest.status}</p>
              </div>

              <div className="grid gap-7 py-4 sm:grid-cols-2">
                <FormGroupInput
                  id="department"
                  label="Department"
                  type="text"
                  placeholder="Enter your department"
                  register={register("department", {
                    required: {
                      value: true,
                      message: "Please enter your department",
                    },
                  })}
                  error={errors.department?.message}
                />
                <FormGroupInput
                  id="nameOfDoctor"
                  label="Name of Doctor"
                  type="text"
                  placeholder="Enter your name of doctor"
                  register={register("nameOfDoctor", {
                    required: {
                      value: true,
                      message: "Please enter your name of doctor",
                    },
                  })}
                  error={errors.nameOfDoctor?.message}
                />
                <FormGroupInput
                  id="treatmentType"
                  label="Treatment Type"
                  type="text"
                  placeholder="Enter your treatment type"
                  register={register("treatmentType", {
                    required: {
                      value: true,
                      message: "Please enter your treatment type",
                    },
                  })}
                  error={errors.treatmentType?.message}
                />
                <FormGroupInput
                  id="plannedTreatmentDate"
                  label="Planned Treatment Date"
                  type="date"
                  placeholder="Enter your planned treatment date"
                  register={register("plannedTreatmentDate", {
                    required: {
                      value: true,
                      message: "Please enter your planned treatment date",
                    },
                  })}
                  error={errors.plannedTreatmentDate?.message}
                />
                <FormGroupInput
                  id="description"
                  label="Description"
                  type="text"
                  placeholder="Enter your description"
                  register={register("description", {
                    required: {
                      value: true,
                      message: "Please enter your description",
                    },
                  })}
                  error={errors.description?.message}
                />
                <FormGroupInput
                  id="additionalComments"
                  label="Additional Comments"
                  type="text"
                  placeholder="Enter your additional comments"
                  register={register("additionalComments", {
                    required: {
                      value: true,
                      message: "Please enter your additional comments",
                    },
                  })}
                  error={errors.additionalComments?.message}
                />
                <FormGroupInput
                  id="medicalFiles"
                  label="Medical Files"
                  type="file"
                  multiple={true}
                  placeholder="Upload your medical files"
                  register={register("medicalFiles", {
                    required: {
                      value: true,
                      message: "Please upload your medical files",
                    },
                  })}
                  error={errors.medicalFiles?.message}
                />
                <FormGroupInput
                  id="billDetailFile"
                  label="Billing Details"
                  type="file"
                  placeholder="Upload your billing details"
                  register={register("billDetailFile", {
                    required: {
                      value: true,
                      message: "Please upload your billing details",
                    },
                  })}
                  error={errors.billDetailFile?.message}
                />
              </div>
            </form>
          )}
          <DialogFooter>
            <Button
              variant="outline"
              isLoading={rejectMutation.isPending}
              disabled={rejectMutation.isPending || submitMutation.isPending}
              onClick={() => rejectMutation.mutate(activeRequest.id)}
            >
              Reject Request
            </Button>
            <Button
              type="submit"
              isLoading={submitMutation.isPending}
              disabled={rejectMutation.isPending || submitMutation.isPending}
              onClick={handleSubmit(async (data) => {
                await submitMutation.mutateAsync({
                  requestId: activeRequest.id,
                  data,
                })
              })}
            >
              Submit to Jireh
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  )
}
