import { cn } from "@/lib/utils"
export default function PatientAuthWrapper({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <main className="flex justify-center py-10 px-5">
      <section
        className={cn(
          " max-w-[400px] rounded-sm w-full flex flex-col gap-7",
          className
        )}
      >
        {children}
      </section>
    </main>
  )
}
