import { Link } from "react-router-dom"
import logo from "@/assets/icons/logo.svg"
export default function GuarantorNavbar() {
  return (
    <div className="w-full fixed top-0 left-0 z-40">
      <nav className="flex gap-5 items-center justify-between w-full px-5 sm:px-10 py-3 bg-white shadow-sm">
        <Link to="/healthcare/" aria-label="Home">
          <img
            src={logo}
            alt="Jireh Logo"
            className="h-12"
            aria-hidden="true"
          />
        </Link>
      </nav>
    </div>
  )
}
