import { Button } from "@/components/Button"
import { Link, useNavigate } from "react-router-dom"
import { usePatientAuthStore } from "../stores/patientAuthStore"
import { Bell, Menu } from "lucide-react"
import Logo from "@/components/Logo"
import cardBackground from "@/assets/images/card-background.png"
import YourTreatments from "../components/YourTreatments"
import UpcomingPayments from "../components/UpcomingPayments"
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/Sheet"
import { formatMoney } from "@/utilities/currencyUtilities"
import { useOnboardingChecklist } from "../hooks/useOnboardingChecklist"
import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import IncompleteSignUp from "../components/IncompleteSignUp"

export default function PatientDashboard() {
  const query = useOnboardingChecklist()
  const user = usePatientAuthStore((state: any) => state.user)

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    return <ErrorBlock />
  }

  const { onboardingRedirectLink } = query.data || {}

  if (onboardingRedirectLink) {
    return <IncompleteSignUp onboardingRedirectLink={onboardingRedirectLink} />
  }

  const membershipStatus = user?.membershipStatus || "PENDING"

  return (
    <div className=" flex flex-col gap-7 mt-10">
      <div className="flex justify-between items-center fixed top-0 left-0 px-5 py-3 w-full bg-white z-50 shadow-sm">
        <Sidebar />

        <h1 className="font-medium text-center ">Hello, {user?.firstName}</h1>

        <Button
          variant="ghost"
          aria-label="notifications"
          className=" relative"
        >
          <Bell className="w-6 h-6" />

          {/* TODO: Add notifications */}
          {0 > 0 && (
            <div className="bg-red-500 h-[10px] w-[10px] rounded-full absolute top-2 right-4 border-[0.2px] border-white"></div>
          )}
        </Button>
      </div>

      <p className="uppercase text-center text-sm font-bold">
        Membership Status:{" "}
        <span className={resolveMembershipStatusColor(membershipStatus)}>
          {membershipStatus}
        </span>
      </p>

      <MembershipCard />
      <YourTreatments />
      <UpcomingPayments />
      <nav
        className="fixed bottom-0 left-0 flex z-50 h-10
        bg-[#0D0D0D]
      "
      ></nav>
    </div>
  )
}

function MembershipCard() {
  const user = usePatientAuthStore((state: any) => state.user)

  const { membershipStatus, creditLimit } = user || {}

  let { amount, currency, remainingAmount } = creditLimit || {}

  let { code: currencyCode } = currency || {}

  currencyCode = currencyCode || "KES"

  amount = amount || 0
  remainingAmount = remainingAmount || 0

  return (
    <div
      className="bg-primary/80 text-white w-full h-48 rounded-3xl grid grid-cols-3 p-5 font-medium shadow-md shadow-gray-500"
      style={{
        backgroundImage: `url(${cardBackground})`,
        backgroundSize: "130% 130%",
        backgroundPosition: "center",
      }}
    >
      <div className="col-span-2">
        <Logo width="50" height="25" fill="white" />
        <p className="mt-1"> Jireh Health Membership</p>
      </div>

      <div className="">
        <p className="text-xs">Available Balance</p>

        <p className="mt-2 text-xl">
          {formatMoney(+remainingAmount, currencyCode)}
        </p>
      </div>

      <div className="text-sm col-span-2 mt-auto">
        <p>Credit Limit {formatMoney(+amount, currencyCode)}</p>
        <p className="mt-1">
          Used {formatMoney(+amount - +remainingAmount, currencyCode)}
        </p>
      </div>
      <Button
        role="link"
        className="w-full text-xs rounded-none mt-auto bg-white text-primary font-medium hover:bg-white"
        disabled={membershipStatus !== "APPROVED"}
      >
        <Link
          to="request-medical-info"
          className="no-underline w-full h-full grid place-content-center"
        >
          Fund Treatment
        </Link>
      </Button>
    </div>
  )
}

function Sidebar() {
  const signOut = usePatientAuthStore((state: any) => state.signOut)
  const navigate = useNavigate()

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button className="" variant="ghost" aria-label="menu">
          <Menu className="w-6 h-6" />
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="flex flex-col gap-7">
        <SheetHeader>
          <SheetTitle>Settings</SheetTitle>
        </SheetHeader>

        <SheetFooter>
          <SheetClose asChild>
            <Button
              variant="outline"
              onClick={() => {
                signOut()
                navigate("/patients/auth/login")
              }}
            >
              Sign Out
            </Button>
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

function resolveMembershipStatusColor(status: string) {
  switch (status) {
    case "PENDING":
      return "text-yellow-500"
    case "APPROVED":
      return "text-green-500"
    case "REJECTED":
      return "text-red-500"
    default:
      return "text-yellow-500"
  }
}
