import { setToLocalStorage } from "@/utilities/localStorage"
import { useEffect } from "react"
import { Route, Routes } from "react-router-dom"
import RouteMetadata from "@/components/RouteMetadata"
import GuarantorInviteSignUp from "./Pages/GuarantorInviteSignUp"
import GuarantorHome from "./Pages/GuarantorHome"
import GuarantorVerifyEmail from "./Pages/GuarantorVerifyEmail"
import GuarantorOnboardingWrapper from "./GuarantorOnboardingWrapper"
import GuarantorsLogin from "./Pages/GuarantorLogin"
import useSetAmplitudeUserId from "@/hooks/useSetAmplitudeUserId"

export default function GuarantorWrapper() {
  useEffect(() => {
    setToLocalStorage("tenantId", "guarantors")
  }, [])

  useSetAmplitudeUserId()

  return (
    <main className="p-10">
      <Routes>
        <Route
          path="/*"
          element={
            <RouteMetadata title="Dashboard">
              <GuarantorHome />
            </RouteMetadata>
          }
        />

        <Route
          path="/invite/:id"
          element={
            <RouteMetadata title="Guarantor Invite">
              <GuarantorInviteSignUp />
            </RouteMetadata>
          }
        />

        <Route path="/auth/login" element={<GuarantorsLogin />} />

        <Route
          path="/auth/signup/verify-email"
          element={
            <RouteMetadata title="Verify Email">
              <GuarantorVerifyEmail />
            </RouteMetadata>
          }
        />

        <Route
          path="/onboarding/*"
          element={
            <RouteMetadata title="Onboarding">
              <GuarantorOnboardingWrapper />
            </RouteMetadata>
          }
        />
      </Routes>
    </main>
  )
}
