import { useNavigate } from "react-router-dom"
import GuarantorAuthTitle from "../components/GuarantorAuthTitle"
import { useToast } from "@/hooks/useToast"
import { useForm } from "react-hook-form"
import { Button } from "@/components/Button"
import FormGroupInput from "@/components/form/FormGroupInput"
import axios from "axios"
import { useMutation } from "@tanstack/react-query"

const allowedFileTypes = [
  "application/pdf",
  "image/jpg",
  "image/jpeg",
  "image/png",
]

type Inputs = {
  bankStatements: FileList
}
export default function GuarantorFinancialData() {
  const navigate = useNavigate()
  const { toast } = useToast()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const mutation = useMutation({
    mutationFn: async (data: Inputs) => {
      const formData = new FormData()

      for (let i = 0; i < data.bankStatements.length; i++) {
        formData.append("financialStatements", data.bankStatements[i])
      }

      await axios.post(
        `${
          import.meta.env.VITE_SUPERTOKENS_API_DOMAIN
        }/guarantors/financial-statements`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
    },
    onSuccess: () => {
      navigate("/guarantors/onboarding/payment-methods")
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
    },
  })

  return (
    <div className="text-center flex flex-col gap-7 items-center">
      <GuarantorAuthTitle>Banking & Financial Data</GuarantorAuthTitle>
      <p>Please fill in your banking and financial information below</p>

      <form
        className="grid  gap-5 w-full max-w-[400px] mx-auto"
        onSubmit={handleSubmit((data) => {
          mutation.mutate(data)
        })}
      >
        <FormGroupInput
          id="bankStatements"
          label="Upload Bank Statements"
          type="file"
          placeholder="Upload Bank Statements"
          register={register("bankStatements", {
            required: {
              value: true,
              message: "Please upload your Bank Statements",
            },
            validate: (files: FileList) => {
              if (files[0].size > 1024 * 1024 * 5) {
                return "File size must be less than 5MB"
              }

              if (!allowedFileTypes.includes(files[0].type)) {
                return "File type must be PDF, JPG, or PNG"
              }
              return true
            },
          })}
          error={errors.bankStatements?.message}
        />

        <Button
          type="submit"
          disabled={mutation.isPending}
          isLoading={mutation.isPending}
        >
          Next
        </Button>
      </form>
    </div>
  )
}
