import { useState } from "react"
import MembershipPlans from "./Patient/components/splashscreens/MembershipPlans"
import ComplianceSplashScreen from "./Patient/components/splashscreens/ComplianceSplashScreen"
import AccountSplashScreen from "./Patient/components/splashscreens/AccountSplashScreen"
import FinalScreen from "./Patient/components/splashscreens/FinalScreen"
import { ChevronRight } from "lucide-react"
import SplashScreenProgressBar from "./SplashScreenProgressBar"
import FinancialInstructionsScreen from "./Patient/components/splashscreens/FinancialInstructionsScreen"
const SplashScreens = () => {
  const [currentScreen, setCurrentScreen] = useState(0)

  const screens = [
    <SplashScreen
      title="Your affordable credit line for quality care."
      hero="Anytime. Anywhere. Anyone."
      image="/card-stack.png"
      showlower={true}
      progressBarNumber={1}
    />,
    <SplashScreen
      title=" Access emergency funding for any care facility"
      hero="of your choice."
      image="/detailed-card.svg"
      showlower={false}
      progressBarNumber={2}
    />,
    <SplashScreen
      title="0% interest rate in the first month of any financing."
      image="/interestrate-card.svg"
      showlower={false}
      progressBarNumber={3}
    />,
    <AccountSplashScreen />,
    <ComplianceSplashScreen />,
    <MembershipPlans
      currentScreen={currentScreen}
      setCurrentScreen={setCurrentScreen}
    />,
    <FinalScreen
      currentScreen={currentScreen}
      setCurrentScreen={setCurrentScreen}
    />,
    <FinancialInstructionsScreen
      currentScreen={currentScreen}
      setCurrentScreen={setCurrentScreen}
    />,
  ]

  const handleNext = () => {
    if (currentScreen < screens.length - 1) {
      setCurrentScreen(currentScreen + 1)
    } else {
      // Redirection to login/signup if this is the last splash screen
    }
  }

  return (
    <div className="h-screen w-full flex items-center justify-center ">
      {screens[currentScreen]}
      {currentScreen >= 0 && currentScreen < 5 && (
        <button
          onClick={handleNext}
          className=" bottom-8 right-8 h-6 w-6 shadow-lg bg-white rounded-full fixed"
        >
          <ChevronRight />
        </button>
      )}
    </div>
  )
}

const SplashScreen = ({
  title,
  image,
  showlower,
  progressBarNumber,
  hero,
}: any) => (
  <div
    className="h-screen w-full flex flex-col"
    style={{
      backgroundImage: `url("background-gradient.png")`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
    }}
  >
    <div
      className="h-screen  overflow-hidden"
      style={{
        backgroundImage: `url("background-contours.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="h-[70%] flex flex-col items-center px-6 md:px-12 lg:px-16">
        <div className="flex items-center justify-between w-full max-w-screen-lg  my-8">
          <img src="/jireh-white.svg" alt="Jireh Logo" className="h-8" />
          <SplashScreenProgressBar currentStep={progressBarNumber} />
        </div>
        <h1 className="text-2xl md:text-3xl lg:text-4xl  text-white text-center ">
          {title}
        </h1>
        <p className="text-2xl md:text-3xl lg:text-4xl  text-white text-center mb-6">
          {hero}
        </p>
        <div
          className={`relative w-full max-w-md md:max-w-lg lg:max-w-xl flex items-center justify-center ${
            showlower ? "mt-4" : "mt-20"
          }`}
        >
          <img src={image} alt="Card Stack Illustration" className="w-full" />
        </div>
      </div>

      {showlower && (
        <div className="curved-edge flex flex-col items-center justify-center  p-10 pb-0 w-full pt-20 text-center text-white mt-auto px-6">
          <h2 className="text-lg font-medium mb-2">
            Your trusted membership for healthcare finance
          </h2>
          <img
            src="/kenya-court-of-arms.png"
            alt="Kenya Logo"
            className="h-20"
          />
        </div>
      )}
    </div>
  </div>
)

export default SplashScreens
