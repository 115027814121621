import PatientAuthHeadline from "../components/PatientAuthHeadline"
import PatientAuthWrapper from "../components/PatientAuthWrapper"
import { Button } from "@/components/Button"
import { useState } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import axios, { HttpStatusCode } from "axios"
import { useNavigate } from "react-router-dom"
import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import { Checkbox } from "@/components/Checkbox"

export const getPatientTermsAndConditionsQueryKey =
  "getPatientTermsAndConditions"
export default function PatientTermsAndConditions() {
  const navigate = useNavigate()

  const [accepted, setAccepted] = useState(false)
  const mutation = useMutation({
    mutationFn: async () => {
      await axios.post(
        `${
          import.meta.env.VITE_SUPERTOKENS_API_DOMAIN
        }/patients/accept-terms-and-conditions`
      )
      navigate("/patients")
    },
  })

  const query = useQuery({
    queryKey: [getPatientTermsAndConditionsQueryKey],
    queryFn: async () => {
      const response = await axios.get(
        `${
          import.meta.env.VITE_SUPERTOKENS_API_DOMAIN
        }/patients/terms-and-conditions`
      )

      if (response.status === HttpStatusCode.Accepted) {
        navigate("/patients")
      }

      return response.data
    },
  })

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    return <ErrorBlock />
  }

  const { content } = query.data
  return (
    <PatientAuthWrapper>
      <PatientAuthHeadline text="Terms and Conditions" />

      <p>Accept the Terms and Conditions to continue</p>

      <div className="shadow-sm flex flex-col gap-7  w-full  h-[50vh] mx-auto border overflow-y-scroll text-left">
        {content}
      </div>

      <div className="flex items-center space-x-2">
        <Checkbox
          id="terms"
          checked={accepted}
          onCheckedChange={() => setAccepted(!accepted)}
        />
        <label
          htmlFor="terms"
          className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        >
          By ticking this box, you confirm that you have read and agree to the
          Terms and Conditions
        </label>
      </div>

      <Button
        disabled={!accepted || mutation.isPending}
        onClick={() => mutation.mutate()}
        isLoading={mutation.isPending}
      >
        Accept
      </Button>
    </PatientAuthWrapper>
  )
}
