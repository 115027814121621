import { Wallet } from "lucide-react"
import Title from "@/components/typography/Title"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import Tag from "@/components/Tag"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/Tabs"

export default function GuarantorDashboard() {
  return (
    <>
      <Affiliates />
    </>
  )
}

const getGuarantorAffiliatesQueryKey = "getGuarantorAffiliates"
function Affiliates() {
  const query = useQuery({
    queryKey: [getGuarantorAffiliatesQueryKey],
    queryFn: async () => {
      const response = await axios.get(
        import.meta.env.VITE_API_BASE_URL + "/guarantors/affiliate-details"
      )

      return response.data
    },
  })

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    return <ErrorBlock />
  }

  const { affiliates } = query.data

  return (
    <section className="flex flex-col gap-7">
      <div>
        <Title>Your Affiliates</Title>
        <p className="text-gray-500 mt-3">
          Manage the individuals that you are guaranteeing for
        </p>
      </div>

      <section className="border rounded-lg p-5 flex flex-col gap-5">
        {affiliates.map((a: any) => {
          return <Affiliate key={a.id} {...a} />
        })}
      </section>
    </section>
  )
}

type AffiliateProps = {
  name: string
  dateOfBirth: string
  address: string
  medicalRequests: any[]
}

function Affiliate({ name, medicalRequests }: AffiliateProps) {
  return (
    <div className="flex flex-col gap-7">
      <div className="flex gap-5 items-center">
        <Wallet className="w-6 h-6 text-gray-500" />

        <h2 className="text-lg font-medium">{name}</h2>
      </div>

      <Tabs defaultValue="loan-requests" className="w-full">
        <TabsList className="grid grid-cols-2 max-w-[400px]">
          <TabsTrigger value="loan-requests">Loan Requests</TabsTrigger>
          <TabsTrigger value="active-loans">Active Loans</TabsTrigger>
        </TabsList>
        <TabsContent value="loan-requests" className="mt-10">
          <ul className="flex flex-col gap-5">
            {medicalRequests.map((m) => {
              return <MedicalRequest key={m.id} name={name} {...m} />
            })}
          </ul>
        </TabsContent>

        <TabsContent value="active-loans" className="mt-10">
          Active Loans
        </TabsContent>
      </Tabs>
    </div>
  )
}

function MedicalRequest({ name, status, dateOfBirth, homeAddress }: any) {
  return (
    <li className="w-full border rounded-lg p-5 font-medium flex flex-col gap-5">
      <div className="flex justify-between gap-5">
        <span> Loan Request For {name}</span>

        <Tag>{status}</Tag>
      </div>

      <div className="flex flex-col gap-3 text-sm font-medium text-gray-500">
        <p>Full Name: {name}</p>
        <p>Date of Birth: {dateOfBirth}</p>
        <p>Address: {homeAddress}</p>
        <p>Care facility</p>
      </div>
    </li>
  )
}
