import FormGroupInput from "@/components/form/FormGroupInput"
import HealthcareAuthTitle from "../components/auth/HealthcareAuthTitle"
import { useForm } from "react-hook-form"
import { Button } from "@/components/Button"
import { useMutation } from "@tanstack/react-query"
import { sendPasswordResetEmail } from "supertokens-web-js/recipe/emailpassword"
import ErrorMessage from "@/components/ErrorMessage"
import SuccessMessage from "@/components/SuccessMessage"

type Inputs = {
  healthcareEmail: string
}

export default function InitiateForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const mutation = useMutation({
    mutationFn: async (data: Inputs) => {
      const response = await sendPasswordResetEmail({
        formFields: [
          {
            id: "email",
            value: data.healthcareEmail,
          },
        ],
      })

      if (response.status === "FIELD_ERROR") {
        // one of the input formFields failed validation
        response.formFields.forEach((formField) => {
          if (formField.id === "email") {
            // Email validation failed (for example incorrect email syntax).
            throw new Error(formField.error)
          }
        })
      } else if (response.status === "PASSWORD_RESET_NOT_ALLOWED") {
        // this can happen due to automatic account linking. Please read our account linking docs
        throw new Error(
          "This email is not associated with any account. Please reset your password using the account you used to sign up"
        )
      } else {
        // reset password email sent.
        return true
      }
    },
  })

  return (
    <div>
      <div className=" absolute left-0 top-0 w-full hidden md:block pl-6 py-6 border-b">
        <img src="/jireh-logo.png" className="h-8 w-auto" />
      </div>
      <form
        className="flex flex-col gap-5 text-center max-w-[350px] mx-auto md:mt-20"
        onSubmit={handleSubmit(async (data) => await mutation.mutate(data))}
      >
        <HealthcareAuthTitle>
          Enter your registered work email.
        </HealthcareAuthTitle>

        <p>We will send you a password reset link.</p>

        <FormGroupInput
          id="healthcareEmail"
          label="Email"
          type="email"
          placeholder="Enter your email address"
          register={register("healthcareEmail", {
            required: {
              value: true,
              message: "Please enter your email address",
            },
            pattern: {
              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: "Please enter a valid email address",
            },
          })}
          error={errors.healthcareEmail?.message}
        />

        {mutation.isError && <ErrorMessage message={mutation.error.message} />}

        {mutation.isSuccess && (
          <SuccessMessage message="Reset password link sent successfully. Please check your email." />
        )}

        <Button isLoading={mutation.isPending} disabled={mutation.isPending}>
          Submit
        </Button>
      </form>
    </div>
  )
}
