import { useNavigate } from "react-router-dom"
import GuarantorAuthTitle from "../components/GuarantorAuthTitle"
import { useToast } from "@/hooks/useToast"
import { useQuery } from "@tanstack/react-query"
import { Controller, useForm } from "react-hook-form"
import axios from "axios"
import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import FormGroupInput from "@/components/form/FormGroupInput"
import { useMutation } from "@tanstack/react-query"
import FormGroupSelect from "@/components/form/FormGroupSelect"
import FormGroupTextarea from "@/components/form/FormGroupTextarea"
import { Button } from "@/components/Button"

const allowedFileTypes = [
  "application/pdf",
  "image/jpg",
  "image/jpeg",
  "image/png",
]

const currencyOptionsQueryKey = "currencyOptions"

type Inputs = {
  employerName: string
  employerAddress: string
  jobTitle: string
  currency: string
  salary: string
  otherMonthlyIncome: string
  monthlyExpenses: string
  financialObligationsDisclosure: string
  employmentContract: FileList
  monthlyPaystub: FileList
}
export default function GuarantorIncomeInfo() {
  const navigate = useNavigate()
  const { toast } = useToast()

  const query = useQuery({
    queryKey: [currencyOptionsQueryKey],
    queryFn: async () => {
      const response = await axios.get(
        `${import.meta.env.VITE_SUPERTOKENS_API_DOMAIN}/currencies`
      )

      return response.data
    },
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>()

  const mutation = useMutation({
    mutationFn: async (data: Inputs) => {
      const formData = new FormData()
      formData.append("employerName", data.employerName)
      formData.append("jobTitle", data.jobTitle)
      formData.append("employerAddress", data.employerAddress)
      formData.append("currency", data.currency)
      formData.append("salary", data.salary)
      formData.append("otherMonthlyIncome", data.otherMonthlyIncome)
      formData.append("monthlyExpenses", data.monthlyExpenses)
      formData.append(
        "financialObligationsDisclosure",
        data.financialObligationsDisclosure
      )
      formData.append("employmentContract", data.employmentContract[0])
      formData.append("monthlyPaystub", data.monthlyPaystub[0])

      await axios.post(
        `${import.meta.env.VITE_SUPERTOKENS_API_DOMAIN}/guarantors/income-info`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
    },
    onSuccess: () => {
      navigate("/guarantors/onboarding/financial-data")
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
    },
  })

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    return <ErrorBlock />
  }

  return (
    <div className="text-center flex flex-col gap-7 items-center">
      <GuarantorAuthTitle>Employment & Income</GuarantorAuthTitle>
      <p>Please fill in your employment details below</p>

      <form
        className="grid sm:grid-cols-2 gap-5 w-full max-w-[600px] mx-auto"
        onSubmit={handleSubmit((data) => {
          mutation.mutate(data)
        })}
      >
        <FormGroupInput
          id="employerName"
          label="Employer Name"
          type="text"
          placeholder="Enter your employer name"
          register={register("employerName", {
            required: {
              value: true,
              message: "Please enter your employer name",
            },
          })}
          error={errors.employerName?.message}
        />
        <FormGroupInput
          id="jobTitle"
          label="Job Title"
          type="text"
          placeholder="Enter your job title"
          register={register("jobTitle", {
            required: {
              value: true,
              message: "Please enter your job title",
            },
          })}
          error={errors.jobTitle?.message}
        />
        <FormGroupInput
          className="sm:col-span-2"
          id="employerAddress"
          label="Employer Address"
          type="text"
          placeholder="Enter your employer address"
          register={register("employerAddress", {
            required: {
              value: true,
              message: "Please enter your employer address",
            },
          })}
          error={errors.employerAddress?.message}
        />

        <Controller
          name="currency"
          control={control}
          rules={{
            required: {
              value: true,
              message: "Please select your currency",
            },
          }}
          render={({ field }) => (
            <FormGroupSelect
              id="currency"
              label="Currency"
              placeholder="Select Currency"
              field={field}
              error={errors.currency?.message}
              options={query.data.currencyOptions}
            />
          )}
        />

        <FormGroupInput
          id="salary"
          label="Salary"
          type="text"
          placeholder="Enter your salary"
          register={register("salary", {
            required: {
              value: true,
              message: "Please enter your salary",
            },
          })}
          error={errors.salary?.message}
        />
        <FormGroupInput
          id="otherMonthlyIncome"
          label="Other Monthly Income"
          type="text"
          placeholder="Enter your other monthly income"
          register={register("otherMonthlyIncome", {
            required: {
              value: true,
              message: "Please enter your other monthly income",
            },
          })}
          error={errors.otherMonthlyIncome?.message}
        />
        <FormGroupInput
          id="monthlyExpenses"
          label="Monthly Expenses"
          type="text"
          placeholder="Enter your monthly expenses"
          register={register("monthlyExpenses", {
            required: {
              value: true,
              message: "Please enter your monthly expenses",
            },
          })}
          error={errors.monthlyExpenses?.message}
        />
        <FormGroupTextarea
          className="sm:col-span-2"
          id="financialObligationsDisclosure"
          label="Please disclose if you have any existing loans, debts or 
financial obligations, or type none in the comment box below"
          placeholder="Enter your financial obligations disclosure"
          register={register("financialObligationsDisclosure", {
            required: {
              value: true,
              message: "Please enter your financial obligations disclosure",
            },
          })}
          error={errors.financialObligationsDisclosure?.message}
        />
        <FormGroupInput
          id="employmentContract"
          label="Upload Employment Contract"
          type="file"
          placeholder="Upload Employment Contract"
          register={register("employmentContract", {
            required: {
              value: true,
              message: "Please upload your Employment Contract",
            },
            validate: (files: FileList) => {
              if (files[0].size > 1024 * 1024 * 5) {
                return "File size must be less than 5MB"
              }

              if (!allowedFileTypes.includes(files[0].type)) {
                return "File type must be PDF, JPG, or PNG"
              }
              return true
            },
          })}
          error={errors.employmentContract?.message}
        />

        <FormGroupInput
          id="monthlyPaystub"
          label="Upload Monthly Paystub"
          type="file"
          placeholder="Upload Monthly Paystub"
          register={register("monthlyPaystub", {
            required: {
              value: true,
              message: "Please upload your Monthly Paystub",
            },
            validate: (files: FileList) => {
              if (files[0].size > 1024 * 1024 * 5) {
                return "File size must be less than 5MB"
              }

              if (!allowedFileTypes.includes(files[0].type)) {
                return "File type must be PDF, JPG, or PNG"
              }
              return true
            },
          })}
          error={errors.monthlyPaystub?.message}
        />

        <Button
          type="submit"
          className="sm:col-span-2"
          disabled={mutation.isPending}
          isLoading={mutation.isPending}
        >
          Next
        </Button>
      </form>
    </div>
  )
}
