import { Button } from "@/components/Button"
import { useNavigate } from "react-router-dom"
import { ChevronLeft } from "lucide-react"
import Title from "@/components/typography/Title"

export default function PatientPageWrapper({
  children,
  title,
  isRoot = false,
}: {
  children: React.ReactNode
  title: string
  isRoot?: boolean
}) {
  const navigate = useNavigate()
  return (
    <>
      <header className="flex w-full items-center gap-5">
        {!isRoot && (
          <Button
            className="text-black"
            variant="link"
            aria-label="Go back"
            onClick={() => navigate(-1)}
          >
            <ChevronLeft className="h-7 w-7" aria-hidden="true" />
          </Button>
        )}
        <Title>{title}</Title>
      </header>

      <section className="flex flex-col w-full gap-5">{children}</section>
    </>
  )
}
