import { Button } from "@/components/Button"
import PatientAuthHeadline from "./PatientAuthHeadline"
import { useNavigate } from "react-router-dom"

export default function IncompleteSignUp({
  onboardingRedirectLink,
}: {
  onboardingRedirectLink: string
}) {
  const navigate = useNavigate()
  return (
    <>
      <PatientAuthHeadline text="Complete Sign Up" />

      <p>
        It looks like you haven't completed your sign up yet. Please complete
        your sign up to continue.
      </p>

      <Button
        role="link"
        onClick={() => navigate(onboardingRedirectLink || "/patients")}
      >
        Complete Sign Up
      </Button>
    </>
  )
}
