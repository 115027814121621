import { Button } from "@/components/Button"
import HealthcareAuthTitle from "../components/auth/HealthcareAuthTitle"
import { Link, useNavigate } from "react-router-dom"
import FormGroupInput from "@/components/form/FormGroupInput"
import { useForm } from "react-hook-form"
import { useMutation } from "@tanstack/react-query"
import ErrorMessage from "@/components/ErrorMessage"
import { signIn } from "supertokens-web-js/recipe/emailpassword"

type Inputs = {
  healthcareEmail: string
  healthcarePassword: string
}

export default function HealthcareLogIn() {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const mutation = useMutation({
    mutationFn: async (data: Inputs) => {
      const response = await signIn({
        formFields: [
          {
            id: "email",
            value: data.healthcareEmail,
          },
          {
            id: "password",
            value: data.healthcarePassword,
          },
        ],
      })

      if (response.status === "FIELD_ERROR") {
        response.formFields.forEach((formField) => {
          if (formField.id === "email") {
            // Email validation failed (for example incorrect email syntax).
            throw new Error(formField.error)
          }
        })
      } else if (response.status === "WRONG_CREDENTIALS_ERROR") {
        throw new Error("Email password combination is incorrect.")
      } else if (response.status === "SIGN_IN_NOT_ALLOWED") {
        // the reason string is a user friendly message
        // about what went wrong. It can also contain a support code which users
        // can tell you so you know why their sign in was not allowed.
        throw new Error(response.reason)
      } else {
        // sign in successful. The session tokens are automatically handled by
        // the frontend SDK.
        navigate("/healthcare/")
      }
    },
  })

  return (
    <div>
      <div className=" absolute left-0 top-0 w-full hidden md:block pl-6 py-6 border-b">
        <img src="/jireh-logo.png" className="h-8 w-auto" />
      </div>
      <form
        className="flex flex-col gap-5 text-center max-w-[350px] mx-auto md:mt-20"
        onSubmit={handleSubmit(async (data) => {
          await mutation.mutate(data)
        })}
      >
        <HealthcareAuthTitle>Welcome back to Jireh</HealthcareAuthTitle>

        <p>Please log in to access your care provider dashboard.</p>

        <p className="font-medium">
          New to Jireh Health?{" "}
          <Link to="/healthcare/auth/signup">Create Account</Link>
        </p>

        <FormGroupInput
          id="healthcareEmail"
          label="Email"
          type="email"
          placeholder="Enter your email address"
          register={register("healthcareEmail", {
            required: {
              value: true,
              message: "Please enter your email address",
            },
            pattern: {
              value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
              message: "Please enter a valid email address",
            },
          })}
          error={errors.healthcareEmail?.message}
        />

        <FormGroupInput
          id="healthcarePassword"
          label="Password"
          type="password"
          placeholder="Enter your password"
          register={register("healthcarePassword", {
            required: {
              value: true,
              message: "Please enter your password",
            },
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters",
            },
          })}
          error={errors.healthcarePassword?.message}
        />

        {mutation.isError && <ErrorMessage message={mutation.error.message} />}
        <Button
          className="w-full"
          type="submit"
          disabled={mutation.isPending}
          isLoading={mutation.isPending}
        >
          Log In
        </Button>

        <p>
          Forgot your password?{" "}
          <Link to="/healthcare/auth/forgot-password">Click Here</Link>
        </p>
      </form>
    </div>
  )
}
