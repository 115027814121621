import ErrorBlock from "@/components/ErrorBlock"
import Description from "../typography/Description"
import HealthcareAuthTitle from "./HealthcareAuthTitle"
import { useMutation, useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useNavigate, useParams } from "react-router-dom"
import LoadingPage from "@/Routes/LoadingPage"
import { Controller, useForm } from "react-hook-form"
import FormGroupInput from "@/components/form/FormGroupInput"
import { Button } from "@/components/Button"
import { signUp } from "supertokens-auth-react/recipe/emailpassword"
import { toast } from "@/hooks/useToast"
import { useState } from "react"
import CheckEmail from "./CheckEmail"
import { sendVerificationEmail } from "supertokens-auth-react/recipe/emailverification"
import PasswordRequirements from "@/components/PasswordRequirements"
import FormGroupSelect from "@/components/form/FormGroupSelect"
import { salutations } from "./HealthcareSignUpBasicDetails"

const queryKey = "getHealthcareWorkerInvite"

type Inputs = {
  facilityId: string
  facilityName: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  title: string
  role: string
  password: string
  confirmPassword: string
  salutation: string
}

export default function HealthcareInviteSignup() {
  const { id } = useParams()
  const navigate = useNavigate()

  const mutation = useMutation({
    mutationFn: async (data: Inputs) => {
      const response = await signUp({
        formFields: [
          {
            id: "inviteId",
            value: id!,
          },
          {
            id: "email",
            value: data.email,
          },
          {
            id: "phoneNumber",
            value: data.phoneNumber,
          },
          {
            id: "password",
            value: data.password,
          },
          {
            id: "firstName",
            value: data.firstName,
          },
          {
            id: "lastName",
            value: data.lastName,
          },
          {
            id: "title",
            value: "Title",
          },
          {
            id: "providerName",
            value: "Hospital",
          },
          {
            id: "providerAddress",
            value: "Address",
          },
          {
            id: "salutation",
            value: data.salutation,
          },
        ],
      })

      if (response.status === "FIELD_ERROR") {
        // one of the input formFields failed validation
        throw new Error(response.formFields[0].error)
      } else if (response.status === "SIGN_UP_NOT_ALLOWED") {
        // the reason string is a user friendly message
        // about what went wrong. It can also contain a support code which users
        // can tell you so you know why their sign up was not allowed.
        throw new Error(response.reason)
      }

      //send verification email
      const verificationResponse = await sendVerificationEmail()
      if (verificationResponse.status === "EMAIL_ALREADY_VERIFIED_ERROR") {
        // This can happen if the info about email verification in the session was outdated.
        // Redirect the user to the home page
        navigate("/healthcare")
      } else {
        // email was sent successfully.
        setShowCheckEmail(true)
      }
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
    },
  })

  const query = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const result = await axios.get(
        import.meta.env.VITE_API_BASE_URL + `/healthcare/invite-worker/${id}`
      )

      return result.data
    },
  })

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<Inputs>()
  const [showCheckEmail, setShowCheckEmail] = useState(false)

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    const { error } = query as any
    return <ErrorBlock message={error.response?.data.message} />
  }

  const { firstName, lastName, facility, email, role } = query.data.invite

  if (showCheckEmail) {
    return <CheckEmail />
  }

  return (
    <div>
      <div className=" absolute left-0 top-0 w-full hidden md:block pl-6 py-6 border-b">
        <img src="/jireh-logo.png" className="h-8 w-auto" />
      </div>
      <div className="text-center ">
        <div className="flex flex-col gap-3">
          <HealthcareAuthTitle>Welcome to Jireh</HealthcareAuthTitle>

          <Description>
            Confirm your details below and complete the sign up process.
          </Description>
        </div>
        <form
          className="grid sm:grid-cols-2 gap-5  max-w-[500px] mx-auto pt-10"
          onSubmit={handleSubmit(async (data) => await mutation.mutate(data))}
        >
          <FormGroupInput
            id="facilityName"
            label="Healthcare Facility Name"
            type="text"
            placeholder="Enter your first name"
            readonly={true}
            register={register("facilityName", {
              value: facility.name,
            })}
            error={errors.firstName?.message}
          />
          <FormGroupInput
            id="role"
            label="Role"
            type="text"
            placeholder="Enter your role"
            readonly={true}
            register={register("role", {
              value: role,
            })}
            error={errors.role?.message}
          />
          <FormGroupInput
            className="sm:col-span-2"
            id="email"
            label="Email"
            type="email"
            placeholder="Enter your email address"
            readonly={true}
            register={register("email", {
              value: email,
            })}
            error={errors.email?.message}
          />

          <Controller
            name="salutation"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <FormGroupSelect
                id="salutation"
                label="Title"
                placeholder="Select Title"
                field={field}
                error={error?.message}
                options={salutations.map((salutation) => ({
                  name: salutation,
                  value: salutation,
                }))}
              />
            )}
            rules={{
              required: {
                value: true,
                message: "Please select your title",
              },
            }}
          />

          <FormGroupInput
            id="firstName"
            label="First Name"
            type="text"
            placeholder="Enter your first name"
            register={register("firstName", {
              value: firstName,
              required: {
                value: true,
                message: "Please enter your first name",
              },
            })}
            error={errors.firstName?.message}
          />
          <FormGroupInput
            id="lastName"
            label="Last Name"
            type="text"
            placeholder="Enter your last name"
            register={register("lastName", {
              value: lastName,
              required: {
                value: true,
                message: "Please enter your last name",
              },
            })}
            error={errors.lastName?.message}
          />
          <FormGroupInput
            id="phoneNumber"
            label="Phone Number"
            type="text"
            placeholder="Enter your phone number"
            register={register("phoneNumber", {
              value: query.data.invite.phoneNumber,
              required: {
                value: true,
                message: "Please enter your phone number",
              },
            })}
            error={errors.phoneNumber?.message}
          />

          <div>
            <FormGroupInput
              id="password"
              label="Password"
              type="password"
              placeholder="Enter your password"
              register={register("password", {
                required: {
                  value: true,
                  message: "Please enter your password",
                },
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              })}
              error={errors.password?.message}
            />
            <PasswordRequirements />
          </div>
          <FormGroupInput
            id="confirmPassword"
            label="Confirm Password"
            type="password"
            placeholder="Confirm your password"
            register={register("confirmPassword", {
              required: {
                value: true,
                message: "Please confirm your password",
              },
              validate: (value) => {
                if (value !== watch("password")) {
                  return "Passwords do not match"
                }
              },
            })}
            error={errors.confirmPassword?.message}
          />

          <Button
            className="sm:col-span-2"
            isLoading={mutation.isPending}
            disabled={mutation.isPending}
          >
            Submit
          </Button>
        </form>
      </div>
    </div>
  )
}
