import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { usePatientAuthStore } from "../stores/patientAuthStore"
import { PatientIdVerificationStatus } from "../enums/PatientIdVerificationStatus"

export const patientLoginDetailsQueryKey = "patientLoginDetails"

export function useOnboardingChecklist() {
  const navigate = useNavigate()
  const setUser = usePatientAuthStore((state: any) => state.setUser)

  return useQuery({
    queryKey: [patientLoginDetailsQueryKey],
    queryFn: async () => {
      //various onboarding checks
      try {
        const res = await axios.get(
          import.meta.env.VITE_API_BASE_URL + "/patients/login-details"
        )

        let onboardingRedirectLink = ""

        function setUserDetails() {
          const userDetails = {
            ...res.data,
            onboardingRedirectLink,
          }

          setUser(userDetails)
          return userDetails
        }

        const { hasVerifiedWhatsApp } = res.data
        //Set when onboarding has not been completed

        if (!hasVerifiedWhatsApp) {
          onboardingRedirectLink = "/patients/verify-whatsapp"
          return setUserDetails()
        }

        const { isVerified } = res.data

        if (!isVerified) {
          onboardingRedirectLink = "/patients/auth/complete-signup/"
          return setUserDetails()
        }

        const { idVerificationStatus } = res.data

        if (idVerificationStatus === PatientIdVerificationStatus.NOT_SET) {
          onboardingRedirectLink = "/patients/verify-id"
          return setUserDetails()
        }

        const { employmentDetailsAreSet } = res.data

        if (!employmentDetailsAreSet) {
          onboardingRedirectLink = "/patients/employment-details"
          return setUserDetails()
        }

        const { hasUploadedFinancialStatements } = res.data

        if (!hasUploadedFinancialStatements) {
          onboardingRedirectLink = "/patients/financial-statements"
          return setUserDetails()
        }

        const { hasSentGuarantorInvites } = res.data

        if (!hasSentGuarantorInvites) {
          onboardingRedirectLink = "/patients/guarantor-information"
          return setUserDetails()
        }

        //TODO: re-enable after app has been published
        // const { hasLinkedSocialAccounts } = res.data

        // //Prevent looping to link-socials page after a redirect from facebook
        // if (!hasLinkedSocialAccounts) {
        //   if (location.pathname !== "/patients/link-socials/facebook") {
        //     onboardingRedirectLink = "/patients/link-socials"
        //     return setUserDetails()
        //   }
        // }

        const { hasAcceptedLatestTermsAndConditions } = res.data

        if (!hasAcceptedLatestTermsAndConditions) {
          onboardingRedirectLink = "/patients/terms-and-conditions"
          return setUserDetails()
        }

        onboardingRedirectLink = ""
        return setUserDetails()
      } catch (error: any) {
        if (error.response?.status === 401) {
          navigate("/patients/auth/login")
          return null
        }

        if (error.response?.status === 403) {
          navigate("/unauthorized")
          return null
        }

        throw error
      }
    },
  })
}
