import { Controller, useForm } from "react-hook-form"
import GuarantorAuthTitle from "../components/GuarantorAuthTitle"
import FormGroupInput from "@/components/form/FormGroupInput"
import { useMutation, useQuery } from "@tanstack/react-query"
import { countryCodesQueryKey } from "@/Routes/Patient/Pages/PatientSignUp"
import axios from "axios"
import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import FormGroupSelect from "@/components/form/FormGroupSelect"
import { validatePhoneNumber } from "@/utilities/validators"
import { Button } from "@/components/Button"
import { useToast } from "@/hooks/useToast"
import { useNavigate } from "react-router-dom"

const allowedFileTypes = [
  "application/pdf",
  "image/jpg",
  "image/jpeg",
  "image/png",
]

type Inputs = {
  dateOfBirth: string
  phoneNumber: string
  phoneNumberCountryCode: string
  nationality: string
  countryOfResidence: string
  address: string
  utilityFile: FileList
  identificationFile: FileList
}

export default function GuarantorPersonalInfo() {
  const { toast } = useToast()
  const navigate = useNavigate()
  const query = useQuery({
    queryKey: [countryCodesQueryKey],
    queryFn: async () => {
      const response = await axios.get(
        `${import.meta.env.VITE_SUPERTOKENS_API_DOMAIN}/country-codes`
      )

      return response.data
    },
  })

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<Inputs>()

  const mutation = useMutation({
    mutationFn: async (data: Inputs) => {
      const formData = new FormData()
      formData.append("phoneNumber", data.phoneNumber)
      formData.append("phoneNumberCountryCode", data.phoneNumberCountryCode)
      formData.append("nationality", data.nationality)
      formData.append("countryOfResidence", data.countryOfResidence)
      formData.append("dateOfBirth", data.dateOfBirth)
      formData.append("address", data.address)
      formData.append("utilityFile", data.utilityFile[0])
      formData.append("identificationFile", data.identificationFile[0])

      const response = await axios.post(
        `${
          import.meta.env.VITE_SUPERTOKENS_API_DOMAIN
        }/guarantors/personal-info`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )

      return response.data
    },
    onSuccess: (result: any) => {
      toast({
        title: "Success",
        description: result.message,
      })
      navigate("/guarantors/onboarding/income-info")
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
    },
  })

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    return <ErrorBlock />
  }

  return (
    <div className="text-center flex flex-col gap-7 items-center">
      <GuarantorAuthTitle>Personal Info</GuarantorAuthTitle>
      <p>Fill in your personal info</p>

      <form
        className="grid sm:grid-cols-2 gap-5"
        onSubmit={handleSubmit(async (data) => {
          await mutation.mutateAsync(data)
        })}
      >
        <Controller
          name="phoneNumberCountryCode"
          control={control}
          rules={{
            required: {
              value: true,
              message: "Please select your country",
            },
          }}
          render={({ field }) => (
            <FormGroupSelect
              id="phoneNumberCountryCode"
              label="Phone Number Country Code"
              placeholder="Select Country Code"
              field={field}
              error={errors.phoneNumberCountryCode?.message}
              options={query.data.countryOptions}
            />
          )}
        />
        <FormGroupInput
          id="phoneNumber"
          label="Phone Number"
          type="text"
          placeholder="Enter your phone number"
          register={register("phoneNumber", {
            validate: (value) => {
              if (
                !validatePhoneNumber({
                  countryCode: watch("phoneNumberCountryCode" as any),
                  phoneNumber: value,
                })
              ) {
                return "Please enter a valid phone number"
              }

              return true
            },
          })}
          error={errors.phoneNumber?.message}
        />
        <FormGroupInput
          id="dateOfBirth"
          label="Date of Birth"
          type="date"
          placeholder="Enter your date of birth"
          register={register("dateOfBirth", {
            required: {
              value: true,
              message: "Please enter your date of birth",
            },
            validate: (value) => {
              if (new Date(value) > new Date()) {
                return "Date must be in the past"
              }

              return true
            },
          })}
          error={errors.dateOfBirth?.message}
        />

        <Controller
          name="nationality"
          control={control}
          rules={{
            required: {
              value: true,
              message: "Please select your country",
            },
          }}
          render={({ field }) => (
            <FormGroupSelect
              id="nationality"
              label="Nationality"
              placeholder="Select Country Code"
              field={field}
              error={errors.nationality?.message}
              options={query.data.countryOptions}
            />
          )}
        />
        <Controller
          name="countryOfResidence"
          control={control}
          rules={{
            required: {
              value: true,
              message: "Please select your country",
            },
          }}
          render={({ field }) => (
            <FormGroupSelect
              id="countryOfResidence"
              label="Country of Residence"
              placeholder="Select Country Code"
              field={field}
              error={errors.countryOfResidence?.message}
              options={query.data.countryOptions}
            />
          )}
        />
        <FormGroupInput
          id="address"
          label="Address"
          type="text"
          placeholder="Enter your address"
          className=""
          register={register("address", {
            required: {
              value: true,
              message: "Please enter your address",
            },
          })}
          error={errors.address?.message}
        />

        <FormGroupInput
          id="utilityFile"
          label="Utility File"
          type="file"
          placeholder="Upload your utility file"
          register={register("utilityFile", {
            required: {
              value: true,
              message: "Please upload your utility file",
            },
            validate: (files: FileList) => {
              if (files[0].size > 1024 * 1024 * 5) {
                return "File size must be less than 5MB"
              }

              if (!allowedFileTypes.includes(files[0].type)) {
                return "File type must be PDF, JPG, or PNG"
              }
              return true
            },
          })}
          error={errors.utilityFile?.message}
        />

        <FormGroupInput
          id="identificationFile"
          label="Identification File"
          type="file"
          placeholder="Upload your identification file"
          register={register("identificationFile", {
            required: {
              value: true,
              message: "Please upload your identification file",
            },
            validate: (files: FileList) => {
              if (files[0].size > 1024 * 1024 * 5) {
                return "File size must be less than 5MB"
              }

              if (!allowedFileTypes.includes(files[0].type)) {
                return "File type must be PDF, JPG, or PNG"
              }
              return true
            },
          })}
          error={errors.identificationFile?.message}
        />

        <Button
          type="submit"
          className="sm:col-span-2"
          disabled={mutation.isPending}
          isLoading={mutation.isPending}
        >
          Next
        </Button>
      </form>
    </div>
  )
}
