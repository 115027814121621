import React, { useEffect } from "react"

export type RouteMetadataProps = {
  title: string
  children: React.ReactNode
}
const RouteMetadata = ({ title, children }: RouteMetadataProps) => {
  useEffect(() => {
    document.title = title + " - Jireh"

    let favicon = document.querySelector(
      "link[rel='icon']"
    ) as HTMLLinkElement | null
    if (!favicon) {
      favicon = document.createElement("link")
      favicon.rel = "icon"
      document.head.appendChild(favicon)
    }
    favicon.href = "/favicon.png"
  }, [title])

  return <>{children}</>
}

export default RouteMetadata
