export function formatMoney(
  amount: number,
  currency: string,
  includeDecimals: boolean = false
): string {
  return amount.toLocaleString("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: includeDecimals ? 2 : 0,
    maximumFractionDigits: includeDecimals ? 2 : 0,
  })
}
