import ProgressBar from "../Progressbar"
import HealthcareAuthTitle from "./HealthcareAuthTitle"
import { Button } from "@/components/Button"
import emailIcon from "@/assets/icons/email.png"

export default function HealthcareRequestCompleteSignUp() {
  return (
    <div>
      <ProgressBar currentStep={3} />
      <div className="flex flex-col items-center justify-center text-center max-w-md mx-auto my-10 space-y-6">
        <img src={emailIcon} alt="Email Icon" className="w-20 h-20" />
        <HealthcareAuthTitle>
          Your documents have been submitted for verification
        </HealthcareAuthTitle>
        <p className="text-gray-600">
          Jireh is reviewing your care provider credentials and documents. You
          will receive an update via email once the verification is complete.
        </p>
        <Button>Complete</Button>
      </div>
    </div>
  )
}
