import { Button } from "@/components/Button"
import HealthcareAuthTitle from "../components/auth/HealthcareAuthTitle"
import ProgressBar from "../components/Progressbar"
import emailIcon from "@/assets/icons/email.png"
import { Link } from "react-router-dom"

export default function HealthcareCompleteSignUp() {
  return (
    <div>
      <ProgressBar currentStep={3} />

      <div className="max-w-[500px] overflow-hidden mx-auto flex flex-col items-center text-center mt-16 gap-6">
        <img src={emailIcon} alt="Email Icon" className="w-44" />
        <HealthcareAuthTitle>
          Your documents have been submitted for verification
        </HealthcareAuthTitle>

        <p className="text-sm text-gray-500">
          Jireh is reviewing your care provider's credentials and documents. You
          will receive an update via email once the verification is complete
        </p>

        <Link to="/healthcare" className="w-full mt-7">
          <Button role="link" className="w-full" size="lg">
            Complete
          </Button>
        </Link>
      </div>
    </div>
  )
}
