import { useForm } from "react-hook-form"
import HealthcarePageContainer from "../HealthcarePageContainer"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import Subtitle from "../typography/SubTitle"
import Description from "../typography/Description"
import { HealthcareWorkerRole } from "../../stores/healthcareUserStore"
import ForbiddenBlock from "@/components/ForbiddenBlock"
import FormGroupInput from "@/components/form/FormGroupInput"
type Inputs = {
  facilityName: string
  facilityAddress: string
  facilityPOBox: string
}

const queryKey = "healthcareProviderProfileDetails"
const allowedRoles = [HealthcareWorkerRole.ADMIN, HealthcareWorkerRole.OWNER]
export default function HealthcareProviderSettings() {
  const {
    register,
    formState: { errors },
  } = useForm<Inputs>()

  const query = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const response = await axios.get(
        import.meta.env.VITE_API_BASE_URL +
          "/healthcare/healthcare-profile-details"
      )

      return response.data
    },
  })

  if (query.isLoading) return <LoadingPage />
  if (query.isError) return <ErrorBlock />

  const {
    firstName,
    role,
    facilityName,
    facilityAddress,
    hospitalRegistrationNumber,
    facilityPOBox,
  } = query.data

  if (!allowedRoles.includes(role)) {
    return <ForbiddenBlock />
  }

  return (
    <HealthcarePageContainer
      title="Care Provider Profile"
      description="Manage your care provider profile"
    >
      <div className="flex flex-col gap-10 border rounded-xl max-w-[1000px]">
        <div className="w-full h-32 bg-primary/30 rounded-xl relative">
          <div
            className="rounded-full w-24 h-24  grid place-content-center text-5xl border-[6px] border-white bg-primary text-white absolute left-1/2 -bottom-8 -ml-12"
            aria-hidden="true"
          >
            {firstName[0]}
          </div>
        </div>

        <div className="text-center flex flex-col gap-2 p-5">
          <h2 className="text-2xl font-medium">{facilityName} </h2>
          <h3 className="font-medium text-gray-500">{facilityAddress}</h3>

          <form className="grid md:grid-cols-2 gap-7 mt-5">
            <FormGroupInput
              id="facilityName"
              label="Facility Name"
              type="text"
              placeholder="Enter your facility name"
              readonly={true}
              register={register("facilityName", {
                value: facilityName,
              })}
              error={errors.facilityName?.message}
            />
            <FormGroupInput
              id="facilityPOBox"
              label="Facility P.O. Box"
              type="text"
              placeholder="Enter your facility P.O. box"
              readonly={true}
              register={register("facilityPOBox", {
                value: facilityPOBox || "-",
              })}
              error={errors.facilityPOBox?.message}
            />

            <FormGroupInput
              className="sm:col-span-2"
              id="facilityAddress"
              label="Facility Address"
              type="text"
              placeholder="Enter your facility address"
              readonly={true}
              register={register("facilityAddress", {
                value: facilityAddress,
              })}
              error={errors.facilityAddress?.message}
            />
          </form>
          <div className="flex flex-col gap-2 mt-10 text-left">
            <Subtitle>Care Provider Verification Documents</Subtitle>
            <Description>
              Once your legal identity verification is approved, you will no
              longer be able to edit it.
            </Description>

            <div className="grid md:grid-cols-2 mt-5 gap-6">
              <div className="flex flex-col gap-2">
                <label
                  className="text-sm font-medium text-gray-500"
                  htmlFor="healthcareRegistrationNumber"
                >
                  Healthcare Registration Number
                </label>

                <input
                  type="text"
                  className="w-full text-gray-400 p-1 rounded-md bg-gray-200 "
                  id="healthcareRegistrationNumber"
                  value={hospitalRegistrationNumber}
                  readOnly
                />
              </div>
              <div className="flex flex-col gap-2">
                <label
                  className="text-sm font-medium text-gray-500"
                  htmlFor="ownerIdNumber"
                >
                  Care Provider Documents
                </label>
                <input
                  type="text"
                  className="w-full text-gray-400 p-1 rounded-md bg-gray-200 "
                  id="ownerIdNumber"
                  value="Submitted"
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </HealthcarePageContainer>
  )
}
