import { Route, Routes } from "react-router-dom"
import { SessionAuth } from "supertokens-auth-react/recipe/session"
import GuarantorPersonalInfo from "./Pages/GuarantorPersonalInfo"
import GuarantorIncomeInfo from "./Pages/GuarantorIncomeInfo"
import GuarantorFinancialData from "./Pages/GuarantorFinancialData"
import GuarantorPaymentMethods from "./Pages/GuarantorPaymentMethods"
import GuarantorAddPaymentMethod from "./Pages/GuarantorAddPaymentMethod"
import GuarantorTermsAndConditions from "./Pages/GuarantorTermsAndConditions"
import RouteMetadata from "@/components/RouteMetadata"

export default function GuarantorOnboardingWrapper() {
  return (
    <SessionAuth requireAuth={true}>
      <Routes>
        <Route
          path="/personal-info"
          element={
            <RouteMetadata title="Personal Info">
              <GuarantorPersonalInfo />
            </RouteMetadata>
          }
        />
        <Route
          path="/income-info"
          element={
            <RouteMetadata title="Income Info">
              <GuarantorIncomeInfo />
            </RouteMetadata>
          }
        />
        <Route
          path="/financial-data"
          element={
            <RouteMetadata title="Financial Data">
              <GuarantorFinancialData />
            </RouteMetadata>
          }
        />
        <Route
          path="/payment-methods"
          element={
            <RouteMetadata title="Payment Methods">
              <GuarantorPaymentMethods />
            </RouteMetadata>
          }
        />
        <Route
          path="/add-payment-method"
          element={
            <RouteMetadata title="Add Payment Method">
              <GuarantorAddPaymentMethod />
            </RouteMetadata>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <RouteMetadata title="Terms and Conditions">
              <GuarantorTermsAndConditions />
            </RouteMetadata>
          }
        />
      </Routes>
    </SessionAuth>
  )
}
