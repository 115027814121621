import GuarantorAuthTitle from "../components/GuarantorAuthTitle"
import { useState } from "react"
import { Button } from "@/components/Button"
import axios from "axios"
import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
import { Checkbox } from "@/components/Checkbox"

export default function GuarantorTermsAndConditions() {
  const navigate = useNavigate()
  const [acceptKyc, setAcceptKyc] = useState(false)
  const [acceptTerms, setAcceptTerms] = useState(false)

  const mutation = useMutation({
    mutationFn: async () => {
      await axios.post(
        `${
          import.meta.env.VITE_SUPERTOKENS_API_DOMAIN
        }/guarantors/accept-terms-and-conditions`
      )
      navigate("/guarantors")
    },
  })

  return (
    <div className=" flex flex-col gap-7 items-center max-w-[500px] mx-auto text-left">
      <GuarantorAuthTitle>Legal & Compliance</GuarantorAuthTitle>
      <p className="max-w-[45ch]">
        Accept the terms and conditions to continue
      </p>

      <div className="shadow-sm flex flex-col gap-7  w-full  h-[50vh] mx-auto border overflow-y-auto text-left">
        KYC, AML, and credit checks
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex items-center space-x-2">
          <Checkbox
            id="terms"
            checked={acceptKyc}
            onCheckedChange={() => setAcceptKyc(!acceptKyc)}
          />
          <label
            htmlFor="terms"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            I consent to KYC, AML, and credit checks
          </label>
        </div>

        <div className="flex items-center space-x-2">
          <Checkbox
            id="terms"
            checked={acceptTerms}
            onCheckedChange={() => setAcceptTerms(!acceptTerms)}
          />
          <label
            htmlFor="terms"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            By checking this box you consent to the gurantor terms and
            conditions
          </label>
        </div>
      </div>

      <Button
        disabled={!acceptKyc || !acceptTerms || mutation.isPending}
        onClick={() => mutation.mutate()}
        isLoading={mutation.isPending}
        className="w-full"
      >
        Accept
      </Button>
    </div>
  )
}
