import Title from "@/components/typography/Title"
import { useGuarantorUserStore } from "../stores/guarantorUserStore"
import FormGroupInput from "@/components/form/FormGroupInput"
import { useForm } from "react-hook-form"
import { useQuery } from "@tanstack/react-query"
import { guarantorPaymentMethodsQueryKey } from "./GuarantorPaymentMethods"
import axios from "axios"
import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Table"
import mastercard from "@/assets/icons/mastercard.svg"
import visa from "@/assets/icons/visa.png"
import { formatDate } from "@/utilities/dateUtilities"

type Inputs = {
  firstName: string
  lastName: string
  email: string
  phone: string
}

export default function GuarantorProfile() {
  const user = useGuarantorUserStore((state: any) => state.user)
  const { firstName, lastName, email, phone } = user || {}

  const {
    register,
    formState: { errors },
  } = useForm<Inputs>()

  const query = useQuery({
    queryKey: [guarantorPaymentMethodsQueryKey],
    queryFn: async () => {
      const response = await axios.get(
        `${
          import.meta.env.VITE_SUPERTOKENS_API_DOMAIN
        }/guarantors/payment-methods`
      )

      return response.data
    },
  })

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    return <ErrorBlock />
  }

  const { paymentMethods } = query.data

  return (
    <div className="flex flex-col gap-7 max-w-[1000px]">
      <div>
        <Title>My profile</Title>
        <p className="text-gray-500 mt-3">Manage your personal profile</p>
      </div>

      <section className="flex flex-col gap-10 border rounded-xl ">
        <div className="w-full h-32 bg-primary/30 rounded-xl relative">
          <div
            className="rounded-full w-24 h-24  grid place-content-center text-5xl border-[6px] border-white bg-primary text-white absolute left-1/2 -bottom-8 -ml-12"
            aria-hidden="true"
          >
            {firstName[0]}
          </div>
        </div>

        <div className="text-center flex flex-col gap-2 p-5">
          <h2 className="text-2xl font-medium">
            {firstName} {lastName}
          </h2>

          <form className="grid md:grid-cols-2 gap-7 mt-5">
            <FormGroupInput
              id="firstName"
              label="First Name"
              type="text"
              placeholder="Enter your first name"
              readonly={true}
              register={register("firstName", {
                value: firstName,
                required: {
                  value: true,
                  message: "Please enter your first name",
                },
              })}
              error={errors.firstName?.message}
            />
            <FormGroupInput
              id="lastName"
              label="Last Name"
              type="text"
              placeholder="Enter your last name"
              readonly={true}
              register={register("lastName", {
                value: lastName,
                required: {
                  value: true,
                  message: "Please enter your last name",
                },
              })}
              error={errors.lastName?.message}
            />
            <FormGroupInput
              id="email"
              label="Email"
              type="email"
              placeholder="Enter your email address"
              readonly={true}
              register={register("email", {
                value: email,
                required: {
                  value: true,
                  message: "Please enter your email address",
                },
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: "Please enter a valid email address",
                },
              })}
              error={errors.email?.message}
            />
            <FormGroupInput
              id="phone"
              label="Phone Number"
              type="tel"
              placeholder="Enter your phone number"
              readonly={true}
              register={register("phone", {
                value: phone,
                required: {
                  value: true,
                  message: "Please enter your phone number",
                },
              })}
              error={errors.phone?.message}
            />
          </form>
        </div>
      </section>

      <section className="flex flex-col gap-7 p-5 border rounded-lg">
        <div>
          <Title>Payment Methods</Title>
          <p className="text-gray-500 mt-3">
            Link your credit cards for repayments if not settled by the primary
            borrower{" "}
          </p>
        </div>
        <div className="flex items-center justify-between gap-5 w-full text-left mt-5">
          <div>
            <h2 className="text-lg font-medium">Linked Accounts</h2>
            <p className="text-gray-500">Your added credit cards</p>
          </div>
        </div>

        <Table className="">
          <TableHeader>
            <TableRow className="bg-gray-200 ">
              <TableHead>Account Type</TableHead>
              <TableHead>Bank Name</TableHead>
              <TableHead>Card Number</TableHead>
              <TableHead>Date Added</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className="text-left">
            {paymentMethods.map((paymentMethod: any) => (
              <TableRow key={paymentMethod.id}>
                <TableCell className="font-medium uppercase flex items-center justify-center gap-1">
                  <img
                    src={resolveCardIcon(paymentMethod.cardDetails.cardType)}
                    alt={`${paymentMethod.cardDetails.cardType} icon`}
                    className="w-10"
                  />
                </TableCell>
                <TableCell className="capitalize">
                  {paymentMethod.cardDetails.bankName}
                </TableCell>
                <TableCell className="capitalize">
                  <div className="flex gap-3 font-medium text-gray-600">
                    <div className="flex items-center">
                      {Array.from({ length: 4 }).map((_, i) => (
                        <span key={i}>*</span>
                      ))}
                    </div>
                    <div className="flex items-center">
                      {Array.from({ length: 4 }).map((_, i) => (
                        <span key={i}>*</span>
                      ))}
                    </div>
                    <div className="flex items-center">
                      {Array.from({ length: 4 }).map((_, i) => (
                        <span key={i}>*</span>
                      ))}
                    </div>
                    <div>{paymentMethod.cardDetails.last4Digits}</div>
                  </div>
                </TableCell>
                <TableCell className="font-medium">
                  {formatDate(paymentMethod.createdAt)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </section>
    </div>
  )
}
function resolveCardIcon(cardType: string) {
  switch (cardType) {
    case "VISA":
      return visa
    case "MASTERCARD":
      return mastercard
    default:
      return visa
  }
}
