import PatientPageWrapper from "../PatientPageWrapper"
import Tag from "@/components/Tag"
import { Info } from "lucide-react"
import sparkle from "@/assets/icons/sparkle.svg"
import insuarancePremium from "@/assets/icons/insuarance-premium.png"
import {
  Dialog,
  DialogDescription,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/components/Dialog"
import { formatMoney } from "@/utilities/currencyUtilities"
import percentTile from "@/assets/icons/percent-tile.png"
import { formatDate } from "@/utilities/dateUtilities"
import gauge from "@/assets/images/gauge.png"
import { Button, ButtonProps } from "@/components/Button"
import { useQuery } from "@tanstack/react-query"
import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import axios from "axios"
import { useParams } from "react-router-dom"
import { usePatientLoanStore } from "../../stores/patientLoanStore"
import { resolveNextPayment } from "../../components/YourTreatments"
import DueInTag from "../../components/DueInTag"
import PaymentPortal from "../Payment/PatientPaymentPortal"
export const getPatientLoanDetailsQueryKey = "getPatientLoanDetails"
export default function ViewLoanDetails() {
  const id = useParams().id
  const setLoan = usePatientLoanStore((state: any) => state.setLoan)

  const query = useQuery({
    queryKey: [getPatientLoanDetailsQueryKey],
    queryFn: async () => {
      const response = await axios.get(
        `${import.meta.env.VITE_API_BASE_URL}/loans/patient/me/${id}`
      )

      setLoan(response.data)
      return response.data
    },
  })

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    return <ErrorBlock />
  }

  return (
    <PatientPageWrapper title="Loan Details">
      <MedicalRequestDetails />
      <LoanInformation />
      <RepaymentSchedule />
    </PatientPageWrapper>
  )
}

function MedicalRequestDetails() {
  const { patientMedicalInfoRequest, healthcareMedicalInfoRequest } =
    usePatientLoanStore((state: any) => state.loan)

  const { treatmentType, nameOfDoctor, department, plannedTreatmentDate } =
    healthcareMedicalInfoRequest

  const { facility, status } = patientMedicalInfoRequest
  return (
    <section className="border-b pt-2 pb-5 flex gap-10 justify-between">
      <div className="text-sm grid gap-1">
        <h2 className="text-xl">{treatmentType}</h2>
        <p className="text-gray-500">{facility.name}</p>
        <p className="text-gray-500">
          {nameOfDoctor} - {department}
        </p>
        <p className="text-red-500 mt-1 font-medium">
          Treatment Date: {formatDate(plannedTreatmentDate)}
        </p>
      </div>

      <Tag>{status}</Tag>
    </section>
  )
}

function LoanInformation() {
  //TODO: update the response to display these details
  const { transactionFeePercent, maxLoanPeriod, lateFeePerWeek } = {
    transactionFeePercent: 2.9,
    maxLoanPeriod: 3,
    lateFeePerWeek: 500,
  }

  const {
    amount,
    currency,
    interestRate,
    transactionFeeIsPaid,
    transactionFee,
  } = usePatientLoanStore((state: any) => state.loan)

  const loanAmount = +amount - (!transactionFeeIsPaid ? transactionFee : 0)

  return (
    <section className="flex flex-col gap-3 border-b pb-5">
      <h2 className="font-medium">Loan Information</h2>
      <Dialog>
        <DialogTrigger className="flex items-center text-primary underline gap-1 text-sm text-left p-0 font-medium ">
          Click here to learn more on your loan details{" "}
          <Info className="w-3 h-3" aria-hidden="true" />
        </DialogTrigger>

        <DialogContent className="sm:max-w-[400px] max-h-screen overflow-y-scroll">
          <DialogHeader className="text-left">
            <DialogTitle>Loan Information</DialogTitle>
            <DialogDescription>
              A breakdown of your loan terms
            </DialogDescription>
          </DialogHeader>

          <section className="flex flex-col gap-5">
            <InfoSection
              title="Loan Amount"
              content={[`${formatMoney(+loanAmount, currency.code)}`]}
              tooltip="The approved amount you can use for your treatment"
            />
            <InfoSection
              title="Transaction fee to Jireh"
              content={[`${transactionFeePercent}% of loan amount`]}
              tooltip={`A small fee(${transactionFeePercent}% of your loan amount) paid to Jireh at the start`}
            />
            <InfoSection
              title="Interest rate per month"
              content={[`0% in the 1st month`, `${interestRate}% thereafter`]}
              tooltip={`Enjoy a 0% interest in your first month, and after that a low ${interestRate}% monthly rate applies`}
            />

            <InfoSection
              title="Max Loan Period"
              content={[`${maxLoanPeriod} months`]}
              tooltip={`You have a maximum of ${maxLoanPeriod} months to pay back your loan in full`}
            />

            <InfoSection
              title="Payment Frequency"
              content={[
                `Flexible repayment in 1st month`,
                `Weekly repayment thereafter`,
              ]}
              tooltip={`Flexible repayments of your loan in the first month, weekly repayments after that.`}
            />

            <InfoSection
              title="Late Fee Per Week"
              content={[formatMoney(lateFeePerWeek, currency.code)]}
              tooltip={`If payments are not met on time, a ${formatMoney(
                lateFeePerWeek,
                currency.code
              )} fee is added each week until payment is received `}
            />
          </section>
        </DialogContent>
      </Dialog>

      <div className="flex flex-col gap-4 mt-3 relative">
        <InfoSection
          title="Loan Amount"
          content={[`${formatMoney(+loanAmount, currency.code)}`]}
          tooltip="Interest-free if repaid within 1 month"
          icon={
            <img
              src={insuarancePremium}
              alt="percent tile"
              aria-hidden="true"
              className="w-7 h-7"
            />
          }
        />
        <InfoSection
          title="Transaction fee to Jireh"
          content={[`${transactionFeePercent}% of loan amount`]}
          icon={
            <img
              src={percentTile}
              alt="percent tile"
              aria-hidden="true"
              className="w-6 h-6"
            />
          }
        />
        <InfoSection
          title="Interest rate per month"
          content={[`0% in the 1st month`, `${interestRate}% thereafter`]}
          icon={
            <img
              src={percentTile}
              alt="percent tile"
              aria-hidden="true"
              className="w-6 h-6"
            />
          }
        />

        <InfoSection
          title="Max Loan Period"
          content={[`${maxLoanPeriod} months`]}
          icon={
            <img
              src={percentTile}
              alt="percent tile"
              aria-hidden="true"
              className="w-6 h-6"
            />
          }
        />

        <InfoSection
          title="Payment Frequency"
          content={[
            `Flexible repayment in 1st month`,
            `Weekly repayment thereafter`,
          ]}
          icon={
            <img
              src={percentTile}
              alt="percent tile"
              aria-hidden="true"
              className="w-6 h-6"
            />
          }
        />

        <InfoSection
          title="Late Fee Per Week"
          content={[formatMoney(lateFeePerWeek, currency.code)]}
          tooltip="Late fee charge on weekly repayment"
          icon={
            <img
              src={percentTile}
              alt="percent tile"
              aria-hidden="true"
              className="w-6 h-6"
            />
          }
        />
        <div className="h-[80%] absolute top-0 left-[21px] bg-primary border -z-10 my-10"></div>
      </div>
    </section>
  )
}

type InfoSectionProps = {
  icon?: React.ReactNode
  title: string
  tooltip?: string
  content: string[]
}
function InfoSection({ title, tooltip, content, icon }: InfoSectionProps) {
  return (
    <div className="flex gap-6 group items-center">
      {icon && (
        <div className="p-2 rounded-full group-first:rounded-none border bg-white grid place-content-center">
          {icon}
        </div>
      )}
      <div className="text-sm">
        <h3 className="text-gray-500  font-medium uppercase">{title}</h3>
        {content.length > 1 ? (
          <ul className="list-disc font-medium uppercase list-inside ml-1">
            {content.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        ) : (
          <p className="font-medium uppercase">{content[0]}</p>
        )}

        {tooltip && (
          <p className="flex text-green-500 mt-2 items-center gap-2">
            <img
              src={sparkle}
              alt="sparkle"
              className="w-3 h-3"
              aria-hidden="true"
            />

            {tooltip}
          </p>
        )}
      </div>
    </div>
  )
}

function RepaymentSchedule() {
  const {
    id,
    healthcareMedicalInfoRequest,
    amount,
    currency,
    totalPaid,
    repaymentSchedule,
    transactionFee,
    transactionFeeIsPaid,
  } = usePatientLoanStore((state: any) => state.loan)

  const { treatmentType } = healthcareMedicalInfoRequest
  const nextPayment = resolveNextPayment(repaymentSchedule.schedule)

  const { monthlyInterest, transactionFeePercent } = {
    monthlyInterest: 2.5,
    transactionFeePercent: 2.9,
  } as any

  const loanAmount = +amount - (!transactionFeeIsPaid ? transactionFee : 0)

  return (
    <section className="">
      <h2 className="font-medium">Repayment Schedule</h2>

      <div className="flex gap-10 justify-between mt-3">
        <div className="text-xs w-2/3  ">
          <p className="uppercase text-gray-500">{treatmentType}</p>
          <p className="uppercase text-gray-500">Balance Amount</p>
          <p className="text-xl font-medium mt-1">
            {formatMoney(+loanAmount - totalPaid, currency.code)}
          </p>
        </div>

        <div className="flex flex-col gap-3 items-end text-right">
          {transactionFeeIsPaid ? (
            <>
              <DueInTag dueDate={nextPayment.dueDate} />

              <p className="text-xs w-fit text-gray-500">
                Weekly payments starting from {formatDate(nextPayment.dueDate)}
              </p>
            </>
          ) : (
            <p className="text-xs max-w-[30ch] text-gray-500">
              Transaction fee of{" "}
              <span className="font-bold text-black">
                {formatMoney(+transactionFee, currency.code)}
              </span>{" "}
              must be paid before your treatment can be disbursed
            </p>
          )}
        </div>
      </div>

      <div className="flex mt-7 gap-10 items-center">
        <img src={gauge} alt="gauge" className="h-20" />

        <div>
          <p className="font-medium text-xs">
            0% Interest & Flexible Repayment Within The First Month
          </p>

          <p className="text-gray-500 text-xs mt-1">
            {monthlyInterest}% Interest rate and weekly repayment schedule from
            the second month onwards
          </p>
        </div>
      </div>

      <div className="mt-7">
        {transactionFeeIsPaid ? (
          <Button size="lg">Pay Now</Button>
        ) : (
          <PaymentPortal
            loanId={id}
            initialPaymentAmount={+transactionFee}
            maxPayableAmount={+amount - totalPaid}
            amountIsChangeable={false}
            title="Pay Transaction Fee"
            description={`Transaction fee of  ${formatMoney(
              +transactionFee,
              currency.code
            )} (${transactionFeePercent}%) of loan amount to disburse medical funds.`}
            isTransactionFeePayment={true}
          >
            <DialogTrigger className="w-full">
              <PayButton size="lg" className="w-full" asChild>
                Pay Transaction Fee
              </PayButton>
            </DialogTrigger>
          </PaymentPortal>
        )}
      </div>
    </section>
  )
}

function PayButton({ children, ...props }: ButtonProps) {
  return (
    <Button
      className="rounded-none mx-auto disabled:bg-gray-500  shadow-sm shadow-gray-500"
      {...props}
    >
      <div className="flex items-center gap-2">{children}</div>
    </Button>
  )
}
