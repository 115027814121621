import { create } from "zustand"
export const useGuarantorUserStore = create((set) => ({
  user: null,
  setUser: (user: any) =>
    set(() => {
      return { user }
    }),
  getUser: () => set((state: any) => state.user),
  removeUser: () =>
    set(async () => {
      return { user: null }
    }),
}))
