import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import { Link, useSearchParams } from "react-router-dom"
import GuarantorAuthTitle from "../components/GuarantorAuthTitle"
import { Button } from "@/components/Button"

const saveGuarantorPaymentMethodQueryKey = "saveGuarantorPaymentMethod"
export default function GuarantorAddPaymentMethod() {
  const [params] = useSearchParams()

  const reference = params.get("reference")

  const query = useQuery({
    queryKey: [saveGuarantorPaymentMethodQueryKey],
    queryFn: async () => {
      const response = await axios.post(
        `${
          import.meta.env.VITE_SUPERTOKENS_API_DOMAIN
        }/guarantors/add-payment-method/${reference}`
      )

      return response.data
    },
  })

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    return <ErrorBlock />
  }

  return (
    <div className="flex flex-col items-center text-center">
      <GuarantorAuthTitle>Payment Method Added Successfully</GuarantorAuthTitle>

      <Link
        to="/guarantors/onboarding/payment-methods"
        className="w-full max-w-[400px] mx-auto mt-7"
      >
        <Button role="link" className="w-full">
          Continue
        </Button>
      </Link>
    </div>
  )
}
