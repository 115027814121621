import { Check, ChevronLeft } from "lucide-react";
import InviteModal from "../InviteModal";
import { useNavigate } from "react-router-dom"

export default function MembershipPlans({
  setCurrentScreen,
  currentScreen,
}: {
  currentScreen: number
  setCurrentScreen: (value: number) => void
}) {
  const navigate = useNavigate()
  const plans = [
    {
      title: "Jireh Plus",
      icon: "/jireh-plus.png",
      price: "Ksh 0",
      description: "Free Membership",
      benefits: [
        "No monthly membership fee",
        "Credit limit of max. KSh 65,000",
        "Only usable for your own medical treatments",
        "0% interest in the first month following your treatment",
        "Low monthly interest rate of 2.5%",
      ],
      buttonText: "Get Started",
      isHighlighted: true,
    },
    {
      title: "Jireh Community",
      icon: "/jireh-community.png",
      price: "Ksh 500",
      description: "Include Family & Friends",
      benefits: [
        "Membership fee of KSh 500 per month",
        "Credit Limit of up to KSh 200,000",
        "Extend your credit to friends and family",
        "No interest in the first month following your treatment",
        "Low monthly interest rate of 2.5%",
      ],
      buttonText: "Sign up to waiting list",
      isHighlighted: false,
    },
    {
      title: "Jireh Elite",
      icon: "/jireh-elite.png",
      price: "Ksh 1,500",
      description: "Include Family & Friends",
      benefits: [
        "Membership fee of KSh 1,500 per month",
        "Credit limit of KSh 650,000",
        "Extend your credit to friends and family",
        "No interest in the first month following your treatment",
        "Low monthly interest rate of 2.5%",
      ],
      buttonText: "Sign up to waiting list",
      isHighlighted: false,
    },
  ]

  return (
    <div className=" h-screen relative bg-white min-h-screen px-4 py-6">
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault()
          setCurrentScreen(currentScreen - 1)
        }}
        className="flex items-center justify-between mb-6 cursor-pointer"
        role="link"
        aria-label="Previous screen"
      >
        <ChevronLeft />
      </a>
      <header className="text-center mb-6">
        <h1 className="text-2xl font-bold text-gray-800 mb-2">
          Jireh Health Memberships
        </h1>
        <p className="text-gray-600">
          Please pick a bundle that suits your preferences.
        </p>
        <div className="flex items-center justify-center mt-4">
          <img
            src="/refer.png"
            alt="Refer a Friend"
            className="h-10 w-10 mr-2"
          />
          <div className="text-left">
            <h2 className="text-sm font-medium text-gray-800">
              Refer a friend
            </h2>
            <p className="text-xs text-gray-600">
              Know someone who would benefit from Jireh?{' '}
              <InviteModal />
            </p>
          </div>
        </div>
      </header>

      <div className="space-y-6">
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`p-6 rounded-lg shadow-lg ${
              plan.isHighlighted
                ? "bg-gradient-to-br from-purple-500 to-purple-600 text-white"
                : "bg-white"
            }`}
          >
            <img
              src={plan.icon}
              alt={plan.title}
              className=" h-12 w-auto mb-4"
            />
            <h2
              className={`text-xl font-bold ${
                plan.isHighlighted ? "text-white" : "text-purple-700"
              } mb-4`}
            >
              {plan.title}
            </h2>
            <p
              className={` ${
                plan.isHighlighted ? "text-white/80" : "text-gray-800"
              } mb-2`}
            >
              <span className="text-3xl  ">{plan.price}</span>
              <span className="m-3">/ per month</span>
            </p>
            {plan.description && (
              <p
                className={`mb-4 ${
                  plan.isHighlighted ? "text-white/70" : "text-gray-600"
                }`}
              >
                {plan.description}
              </p>
            )}
            <ul
              className={`list-none space-y-1 ${
                plan.isHighlighted ? "text-white/90" : "text-gray-700"
              } mb-6`}
            >
              {plan.benefits.map((benefit, idx) => (
                <li key={idx} className="flex items-center gap-2">
                  <Check
                    className={`rounded-full h-4 w-4 p-1 ${
                      plan.isHighlighted
                        ? "bg-white text-purple-600"
                        : "bg-purple-500 text-white"
                    }`}
                  />
                  <span>{benefit}</span>
                </li>
              ))}
            </ul>

            <button
              onClick={() =>
                plan.buttonText === "Get Started"
                  ? navigate("/patients/auth")
                  : setCurrentScreen(7)
              }
              className={`w-full py-2 rounded-md  ${
                plan.isHighlighted
                  ? "bg-white text-purple-600 hover:bg-purple-50"
                  : "bg-purple-500 text-white hover:bg-purple-600"
              }`}
            >
              {plan.buttonText}
            </button>
          </div>
        ))}
      </div>
    </div>
  )
}
