import { cn } from "@/lib/utils"
export default function Tag({
  children,
  className,
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <div
      className={cn(
        "h-5 bg-green-500 text-white p-3 font-medium w-fit grid place-content-center uppercase text-xs",
        className
      )}
    >
      {children}
    </div>
  )
}
