import SplashScreenProgressBar from "@/Routes/SplashScreenProgressBar"

export default function AccountSplashScreen() {
  return (
    <div
      className="  w-full h-[100dvh] flex flex-col overflow-hidden relative"
      style={{
        backgroundImage: `url("background-gradient.png")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}    >
      <div className="h-[70%] relative flex flex-col space-y-2 items-center justify-start">
        <div className="flex items-center justify-between w-full max-w-screen-lg px-6 md:px-12 lg:px-16 my-8">
          <img src="/jireh-white.svg" alt="Jireh Logo" className="h-8" />
          <SplashScreenProgressBar currentStep={4} />
        </div>
        <h1 className="text-xl md:text-2xl font-semibold text-white text-center mb-6">
          How it Works?
        </h1>
        <div className=" absolute -bottom-32 mx-4 text-center max-w-md md:max-w-lg lg:max-w-xl w-full h-full p-10">
          <img
            src="/account-details.png"
            alt="Partially Visible Card"
            className=" mx-auto object-contain"
          />
        </div>
      </div>
      <div className="curved-edge flex items-center justify-center p-10 pb-0 w-full pt-20 text-center text-white  h-[30%] ">
        <div className=" flex flex-col items-start gap-1 max-w-md text-sm relative ">
          <div className="absolute left-[1px] top-3 bottom-2 w-[2px] bg-green-600 rounded-full"></div>
          {[
            "Sign up for Jireh Health Membership",
            "Get approved",
            "Request treatment funding",
            "Jireh pays for your medical bill",
            "Get the quality care you deserve",
          ].map((step, index) => (
            <div key={index} className="flex items-center text-xs mt-1">
              <div className="relative flex items-center justify-center">
                {index === 0 && (
                  <div className="absolute w-3 h-3 bg-white rounded-full"></div>
                )}
                <div className="w-[4px] h-[4px] bg-green-600 rounded-full"></div>
              </div>
              <span className="ml-6">{step}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
