type SplashScreenProgressBarProps = {
  currentStep: number
  activeColor?: string
  inactiveColor?: string
}

export default function SplashScreenProgressBar({
  currentStep,
  activeColor = "bg-green-800",
  inactiveColor = "bg-gray-300",
}: SplashScreenProgressBarProps) {
  return (
    <div className="flex items-center gap-2">
      {Array.from({ length: 5 }).map((_, index) => (
        <div
          key={index}
          className={`h-1 w-6 rounded-full ${
            index < currentStep ? activeColor : inactiveColor
          }`}
        ></div>
      ))}
    </div>
  )
}
