import { Button } from "@/components/Button"
import LoadingPage from "@/Routes/LoadingPage"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { Link, NavigateFunction } from "react-router-dom"
import { verifyEmail } from "supertokens-auth-react/recipe/emailverification"
import Session from "supertokens-web-js/recipe/session"
import {
  HealthcareUser,
  HealthcareWorkerRole,
} from "../../stores/healthcareUserStore"
import ErrorBlock from "@/components/ErrorBlock"

export default function VerifyEmailForm({
  navigate,
}: {
  navigate: NavigateFunction
}) {
  const verificationQuery = useQuery({
    queryKey: ["verifyHealthcareEmail"],
    queryFn: async () => {
      const doesSessionExist = await Session.doesSessionExist()
      if (!doesSessionExist) {
        throw new Error(
          "Something went wrong and we could not verify your email. Please try again later."
        )
      }

      const response = await verifyEmail()
      if (response.status === "EMAIL_VERIFICATION_INVALID_TOKEN_ERROR") {
        // This can happen if the verification code is expired or invalid.
        // You should ask the user to retry
        throw new Error(
          "Oops! Seems like the verification link expired. Please try again"
        )
      }

      const loginDetails = await axios.get(
        import.meta.env.VITE_API_BASE_URL + "/healthcare/login-details"
      )

      const { role } = loginDetails.data as HealthcareUser

      const isOwner = role === HealthcareWorkerRole.OWNER

      //Check if email has already been verified. If it has, redirect to next step.
      const validationErrors = await Session.validateClaims()

      if (validationErrors.length === 0 && isOwner) {
        navigate("/healthcare/auth/signup/verify-id")
      }

      return isOwner
    },
  })

  if (verificationQuery.isLoading) {
    return <LoadingPage />
  }
  if (verificationQuery.isError) {
    return <ErrorBlock message={verificationQuery.error.message} />
  }

  const isOwner = verificationQuery.data
  return (
    <>
      <button onClick={() => navigate(-1)} className="flex items-center">
        <span className="mr-2">←</span> Back
      </button>
      <div className="flex flex-col gap-5 text-center items-center">
        Your email has been verified successfully.
        {isOwner ? (
          <Link to="/healthcare/auth/signup/verify-id">
            <Button>Continue With Sign Up</Button>
          </Link>
        ) : (
          <Link to="/healthcare/">
            <Button>Go To Dashboard</Button>
          </Link>
        )}
      </div>
    </>
  )
}
