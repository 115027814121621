import AccordionMenu from "@/components/AccordionMenu"
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/Tabs"
import { WalletCards } from "lucide-react"
import { useState } from "react"
import ArrowButton from "./ArrowButton"
import giftBox from "@/assets/images/gift-box.png"
import { usePatientAuthStore } from "../stores/patientAuthStore"
import Tag from "@/components/Tag"
import { ScrollArea } from "@/components/ScrollArea"
import { formatMoney } from "@/utilities/currencyUtilities"
import PlusLink from "./PlusLink"
import { formatDate } from "@/utilities/dateUtilities"
import { Link } from "react-router-dom"
import { Button } from "@/components/Button"

export default function YourTreatments() {
  const [showTreatments, setShowTreatments] = useState(true)
  const user = usePatientAuthStore((state: any) => state.user)

  const { medicalRequests, loans } = user || {}

  return (
    <AccordionMenu
      title="Your Jireh Treatments"
      description="All your medical loan requests and application tracking"
      buttonLabel="view treatments"
      icon={<WalletCards className="w-5 h-5 text-gray-500" />}
      active={showTreatments}
      buttonDisabled={false}
      onClick={() => setShowTreatments(!showTreatments)}
    >
      {showTreatments && (
        <Tabs
          defaultValue={loans.length > 0 ? "activeLoans" : "requests"}
          className="w-full mt-5"
        >
          <TabsList className="grid grid-cols-2 ">
            <TabsTrigger value="requests">
              Requests {`(${medicalRequests.length})`}
            </TabsTrigger>
            <TabsTrigger value="activeLoans">
              Active Loans {`(${loans.length})`}
            </TabsTrigger>
          </TabsList>

          <MedicalRequests />
          <ActiveLoans />
        </Tabs>
      )}
    </AccordionMenu>
  )
}

function MedicalRequests() {
  const user = usePatientAuthStore((state: any) => state.user)

  const { membershipStatus, medicalRequests } = user || {}

  return (
    <TabsContent value="requests" className="mt-5 p-2">
      {medicalRequests.length > 0 ? (
        <ScrollArea className="max-h-[400px] overflow-y-auto">
          <ul className="flex flex-col gap-3">
            {medicalRequests.map((request: any) => (
              <MedicalRequest key={request.id} medicalRequest={request} />
            ))}
          </ul>
        </ScrollArea>
      ) : (
        <div className="flex gap-10 items-center">
          <img
            src={giftBox}
            alt="gift box"
            className="w-16 h-16"
            aria-hidden={true}
          />

          <div className="flex flex-col gap-5">
            <p className=" text-gray-500 text-sm">
              Your medical loan requests will appear here
            </p>

            <ArrowButton disabled={membershipStatus !== "APPROVED"}>
              <Link
                to="request-medical-info"
                className="no-underline w-full h-full grid place-content-center text-white"
              >
                Fund New Treatment
              </Link>
            </ArrowButton>
          </div>
        </div>
      )}
    </TabsContent>
  )
}

function MedicalRequest({ medicalRequest }: { medicalRequest: any }) {
  const { status, treatmentType, assignedDoctor, description, facility } =
    medicalRequest

  const loan = medicalRequest.loan

  let loanAmount = 0

  if (loan) {
    const { amount, transactionFeeIsPaid, transactionFee } = loan

    //Deduct transaction fee from loan if transaction fee is not paid.
    loanAmount = +amount - (!transactionFeeIsPaid ? transactionFee : 0)
  }

  return (
    <li className="flex flex-col gap-4  p-3 border rounded-lg">
      <div className="flex justify-between">
        <div className="text-sm flex flex-col gap-1">
          <p className="font-medium text-black text-lg">{treatmentType}</p>

          {loan && (
            <>
              <div>
                <p className="text-lg">
                  {formatMoney(loanAmount, loan.currency.code)}
                </p>
              </div>
            </>
          )}
          <p className="text-gray-500">{assignedDoctor}</p>
          <p className="text-gray-500 text-xs">{facility.name}</p>
        </div>

        <div className="flex flex-col gap-5">
          <Tag className={resolveStatusColor(status)}>{status}</Tag>
        </div>
      </div>

      <p className="flex flex-col gap-2 text-sm text-gray-500 max-h-20 ">
        {description.length < 300
          ? description
          : description.slice(0, 300) + "..."}
      </p>

      {loan && (
        <Button className="w-full bg-green-500 hover:bg-green-500/80 text-white border-green-500 border">
          <Link
            to={`/patients/loan-details/${loan.id}`}
            className="no-underline w-full h-full grid place-content-center text-white"
          >
            Pay Treatment
          </Link>
        </Button>
      )}
    </li>
  )
}

function resolveStatusColor(status: string) {
  switch (status) {
    case "PENDING":
      return "bg-yellow-500"
    case "APPROVED":
      return "bg-green-500"
    case "REJECTED":
      return "bg-red-500"
    default:
      return "bg-yellow-500"
  }
}

function ActiveLoans() {
  const { loans } = usePatientAuthStore((state: any) => state.user)

  return (
    <TabsContent value="activeLoans" className="mt-5 p-2">
      {loans.length > 0 ? (
        <ul className="flex flex-col gap-3">
          {loans.map((loan: any) => (
            <Loan key={loan.id} loan={loan} />
          ))}
        </ul>
      ) : (
        <div className="flex gap-10 items-center">
          <img
            src={giftBox}
            alt="gift box"
            className="w-16 h-16"
            aria-hidden={true}
          />

          <div className="flex flex-col gap-5 ">
            <p className=" text-gray-500 text-sm">
              Your active loans will appear here
            </p>
          </div>
        </div>
      )}
    </TabsContent>
  )
}

function Loan({ loan }: { loan: any }) {
  const { currency, amount, totalPaid, id, repaymentSchedule } = loan || {}

  const { treatmentType } = loan.patientMedicalInfoRequest || {}

  const nextPayment = resolveNextPayment(repaymentSchedule.schedule)

  return (
    <li className="flex justify-between gap-4  p-3 border rounded-lg">
      <div className="text-sm flex flex-col gap-3">
        <p className="font-medium text-black text-base">{treatmentType}</p>

        <div>
          <p className="text-gray-500 ">Outstanding Amount</p>
          <p className="text-lg">
            {formatMoney(+amount - totalPaid, currency.code)}
          </p>
        </div>

        <div>
          <p className="text-gray-500 ">Next Payment</p>
          <p className="text-sm">
            {formatMoney(+nextPayment.amount, currency.code)} -{" "}
            {formatDate(nextPayment.dueDate)}
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-5 justify-between items-end">
        <Tag>Repayment</Tag>

        <PlusLink link={`/patients/loan-details/${id}`} text="View Details" />
      </div>
    </li>
  )
}

export function resolveNextPayment(repaymentSchedule: any[]) {
  //current date
  const currentDate = new Date()

  return repaymentSchedule.find((p) => {
    const dueDate = new Date(p.dueDate)
    if (dueDate > currentDate && !p.isFirstMonth) {
      return p
    }
  })
}
