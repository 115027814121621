import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { useGuarantorUserStore } from "../stores/guarantorUserStore"

export const useOnboardingChecklist = () => {
  const navigate = useNavigate()
  const setUser = useGuarantorUserStore((state: any) => state.setUser)

  return useQuery({
    queryKey: ["guarantorLoginDetails"],
    queryFn: async () => {
      try {
        const res = await axios.get(
          `${
            import.meta.env.VITE_SUPERTOKENS_API_DOMAIN
          }/guarantors/login-details`
        )

        function setUserDetails(onboardingRedirectLink: string) {
          const user = {
            ...res.data,
            onboardingRedirectLink,
          }
          setUser(user)
          return user
        }

        const {
          hasSetPersonalInfo,
          hasSetIncomeInfo,
          hasSetFinancialData,
          hasSetPaymentMethods,
          hasAcceptedTermsAndConditions,
        } = res.data

        if (!hasSetPersonalInfo) {
          return setUserDetails("/guarantors/onboarding/personal-info")
        }

        if (!hasSetIncomeInfo) {
          return setUserDetails("/guarantors/onboarding/income-info")
        }

        if (!hasSetFinancialData) {
          return setUserDetails("/guarantors/onboarding/financial-data")
        }

        if (!hasSetPaymentMethods) {
          return setUserDetails("/guarantors/onboarding/payment-methods")
        }

        if (!hasAcceptedTermsAndConditions) {
          return setUserDetails("/guarantors/onboarding/terms-and-conditions")
        }

        return setUserDetails("")
      } catch (error: any) {
        if (error.response?.status === 401) {
          navigate("/guarantors/auth/login")
          return null
        }

        if (error.response?.status === 403) {
          navigate("/unauthorized")
          return null
        }

        throw error
      }
    },
  })
}
