import Title from "@/components/typography/Title"
import Description from "./typography/Description"
import { ArrowLeftIcon } from "lucide-react"
import { useNavigate } from "react-router-dom"

export default function HealthcarePageContainer({
  title,
  description,
  children,
}: {
  title: string
  description: string
  children: React.ReactNode
}) {
  const navigate = useNavigate()
  return (
    <div className="w-full flex flex-row items-start gap-16 ">
      <button
        onClick={() => {
          navigate(-1)
        }}
        className="flex items-center gap-2"
      >
        <ArrowLeftIcon />
        <span>Back</span>
      </button>
      <div className="w-full flex flex-col gap-10 ">
        <div className="flex flex-col gap-2 ">
          <Title>{title}</Title>
          <Description>{description}</Description>
        </div>{" "}
        {children}
      </div>
    </div>
  )
}
