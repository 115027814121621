import FormGroupInput from "@/components/form/FormGroupInput"
import HealthcareAuthTitle from "./HealthcareAuthTitle"
import { useForm } from "react-hook-form"
import { useMutation } from "@tanstack/react-query"
import axios from "axios"
import { NavigateFunction } from "react-router-dom"
import { Button } from "@/components/Button"
import ErrorMessage from "@/components/ErrorMessage"
import ProgressBar from "../Progressbar"

const allowedFileTypes = [
  "application/pdf",
  "image/jpg",
  "image/jpeg",
  "image/png",
]

type Inputs = {
  hospitalRegistrationNumber: string
  hospitalLicenseFile: FileList
}

export default function UploadDocumentsForm({
  navigate,
}: {
  navigate: NavigateFunction
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>()

  const mutation = useMutation({
    mutationFn: async (data: Inputs) => {
      const formData = new FormData()
      formData.append(
        "hospitalRegistrationNumber",
        data.hospitalRegistrationNumber
      )
      formData.append("hospitalLicenseFile", data.hospitalLicenseFile[0])

      await axios.post(
        `${
          import.meta.env.VITE_SUPERTOKENS_API_DOMAIN
        }/healthcare/upload-hopsital-document`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      navigate("/healthcare/auth/signup/complete-signup")
    },
  })

  return (
    <div>
      <ProgressBar currentStep={2} />
      <button onClick={() => navigate(-1)} className="flex items-center">
        <span className="mr-2">←</span> Back
      </button>
      <form
        className="flex flex-col gap-7 text-center max-w-[400px] mx-auto"
        onSubmit={handleSubmit(async (data) => await mutation.mutate(data))}
      >
        <HealthcareAuthTitle>
          Verify your care facility to complete registration
        </HealthcareAuthTitle>
        <FormGroupInput
          id="hospitalRegistrationNumber"
          label="Care Provider Registration Number"
          type="text"
          placeholder="Enter your Care Provider Registration Number"
          register={register("hospitalRegistrationNumber", {
            required: {
              value: true,
              message: "Please enter your Care Provider Registration Number",
            },
            maxLength: {
              value: 25,
              message:
                "Care Provider Registration Number must be less than 25 digits",
            },
          })}
          error={errors.hospitalRegistrationNumber?.message}
        />

        <FormGroupInput
          id="hospitalLicenseFile"
          label="Hospital License File"
          type="file"
          placeholder="Upload your Hospital License File"
          register={register("hospitalLicenseFile", {
            required: {
              value: true,
              message: "Please upload your Hospital License File",
            },
            validate: (files: FileList) => {
              if (files[0].size > 1024 * 1024 * 5) {
                return "File size must be less than 5MB"
              }

              if (!allowedFileTypes.includes(files[0].type)) {
                return "File type must be PDF, JPG, or PNG"
              }
              return true
            },
          })}
          error={errors.hospitalLicenseFile?.message}
        />

        {mutation.isError && <ErrorMessage message={mutation.error.message} />}

        <Button
          className="mt-4 bg-purple-600 hover:bg-purple-700 text-white py-2 rounded-lg"
          isLoading={mutation.isPending}
          disabled={mutation.isPending}
        >
          Submit Documents
        </Button>
      </form>
    </div>
  )
}
