import { useQuery } from "@tanstack/react-query"
import { verifyEmail } from "supertokens-auth-react/recipe/emailverification"
import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import Session from "supertokens-web-js/recipe/session"

const verifyAdminQueryKey = "verifyAdmin"

export default function AdminVerifyEmail() {
  const query = useQuery({
    queryKey: [verifyAdminQueryKey],
    queryFn: async () => {
      const response = await verifyEmail()
      if (response.status === "EMAIL_VERIFICATION_INVALID_TOKEN_ERROR") {
        // This can happen if the verification code is expired or invalid.
        // You should ask the user to retry
        throw new Error(
          "Oops! Seems like the verification link expired. Please try again"
        )
      }

      //Check if email has already been verified. If it has, redirect to next step.
      const validationErrors = await Session.validateClaims()

      if (validationErrors.length > 0) {
        throw new Error("Something went wrong with the email verification")
      }

      return true
    },
  })

  if (query.isError) {
    return <ErrorBlock message={query.error.message} />
  }

  if (query.isLoading) {
    return <LoadingPage />
  }

  return (
    <>
      <div className="flex flex-col gap-5 text-center items-center">
        Your email has been verified successfully.
      </div>
    </>
  )
}
