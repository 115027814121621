import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import axios from "axios"
import { useQuery } from "@tanstack/react-query"
import { Link, useSearchParams } from "react-router-dom"
import PatientAuthWrapper from "../../components/PatientAuthWrapper"
import logo from "@/assets/icons/logo.svg"
import { Button } from "@/components/Button"
import { formatMoney } from "@/utilities/currencyUtilities"

export const patientTransactionResultQueryKey = "patientTransactionResult"

export default function TransactionResult() {
  const [params] = useSearchParams()

  const reference = params.get("reference")

  const query = useQuery({
    queryKey: [patientTransactionResultQueryKey],
    queryFn: async () => {
      const response = await axios.get(
        import.meta.env.VITE_SUPERTOKENS_API_DOMAIN +
          `/patients/payments/transaction-result/${reference}`
      )

      return response.data
    },
  })

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    return <ErrorBlock />
  }

  const { status, amount, currency, description, callback } = query.data

  const { title, description: paymentDescription } = resolvePaymentText(status)

  return (
    <PatientAuthWrapper className="items-center justify-center text-center">
      <img src={logo} alt="logo" className="w-full max-w-[150px] mx-auto" />
      <h1 className="font-medium text-xl">{title}</h1>

      <p className="text-sm">{description || paymentDescription}</p>

      {status === "success" && (
        <p>
          Transaction fee of{" "}
          <span className="font-medium">{formatMoney(amount, currency)}</span>{" "}
          has been successfully paid to Jireh
        </p>
      )}

      <Link to={callback || "/patients"} className="w-full">
        <Button role="link" className="w-full">
          Done
        </Button>
      </Link>
    </PatientAuthWrapper>
  )
}

function resolvePaymentText(status: string) {
  switch (status) {
    case "pending":
      return {
        title: "Payment Pending",
        description:
          "Your payment is pending. We will notify you once it is completed",
      }
    case "success":
      return {
        title: "Payment Successful",
        description:
          "Your payment has been successful. You will receive a confirmation email shortly",
      }
    case "failed":
      return {
        title: "Payment Failed",
        description:
          "Your payment has failed. Please try again or contact support",
      }
    case "reversed":
      return {
        title: "Payment Reversed",
        description:
          "Your payment has been reversed. Please try again or contact support",
      }
    default:
      return {
        title: "Payment Status Unknown",
        description: "Your payment status is unknown. Please contact support",
      }
  }
}
