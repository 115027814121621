import { useQuery, useQueryClient } from "@tanstack/react-query"
import {
  HealthcareWorkerRole,
  useHealthcareUserStore,
} from "../../stores/healthcareUserStore"
import HealthcarePageContainer from "../HealthcarePageContainer"
import Description from "../typography/Description"
import Title from "../typography/Title"
import axios from "axios"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Table"
import ForbiddenBlock from "@/components/ForbiddenBlock"
import ErrorBlock from "@/components/ErrorBlock"
import { EllipsisVertical, Plus, Wallet } from "lucide-react"
import { useState } from "react"
import { Popover } from "@/components/Popover"
import { PopoverContent, PopoverTrigger } from "@radix-ui/react-popover"
import LoadingPage from "@/Routes/LoadingPage"
import { Button } from "@/components/Button"
import { formatDate } from "@/utilities/dateUtilities"
import DialogTriggerButton from "@/components/DialogTriggerButton"
import {
  DialogHeader,
  DialogDescription,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogFooter,
  DialogTrigger,
} from "@/components/Dialog"
import { Controller, useForm } from "react-hook-form"
import FormGroupInput from "@/components/form/FormGroupInput"
import FormGroupSelect from "@/components/form/FormGroupSelect"
import { useMutation } from "@tanstack/react-query"
import { toast } from "@/hooks/useToast"
import Subtitle from "../typography/SubTitle"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/Tabs"
import ProtectedResource from "../HealthcareProtectedResource"

const queryKey = "getHealthcareFacilityUsers"

type Inputs = {
  firstName: string
  lastName: string
  email: string
  title: string
  role: string
}

export default function UserManagementSettings({}) {
  return (
    <HealthcarePageContainer
      title="Team Access"
      description="Manage who has access to your care provider portal"
    >
      <Tabs defaultValue="members" className="w-full">
        <TabsList className="grid grid-cols-2 max-w-[400px]">
          <TabsTrigger value="members">
            <Wallet className="h-4" />
            Members
          </TabsTrigger>
          <TabsTrigger value="roles">
            <img src="/roles-panel.png" />
            Roles
          </TabsTrigger>
        </TabsList>

        <TabsContent value="members" className="mt-10">
          <UserManagement />
        </TabsContent>

        <TabsContent value="roles" className="mt-10">
          <Roles />
        </TabsContent>
      </Tabs>
    </HealthcarePageContainer>
  )
}

function Roles() {
  return (
    <div className="w-full">
      <div className="flex flex-col gap-5 border-b">
        <div className="flex flex-col gap-2 pb-4 border-b ">
          <Title>Default Roles</Title>
          <Description>
            View roles and permissions on what users can do on your care
            provider platform
          </Description>
        </div>

        <ul className="flex flex-col gap-7 pb-4">
          <li>
            <p className="font-medium">Owner</p>
            <Description>
              Has the highest level of access and control of the entire system
            </Description>
          </li>
          <li>
            <p className="font-medium">Admin</p>
            <Description>
              Can view and manage some aspects of the system
            </Description>
          </li>
        </ul>
      </div>

      <div className="flex flex-col gap-5 mt-16">
        <div className="flex flex-col gap-2 pb-4 border-b ">
          <Title>Role Permissions</Title>
          <Description>
            View roles and permissions on what users can do on your care
            provider platform
          </Description>
        </div>

        <Table>
          <TableHeader>
            <TableRow className="bg-gray-200 ">
              <TableHead>Category</TableHead>
              <TableHead>Details</TableHead>
              <TableHead>Owner</TableHead>
              <TableHead>Admin</TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            <TableRow>
              <TableCell className="font-medium">User Management</TableCell>
              <TableCell className="flex flex-col">
                <ul className="list-disc text-gray-500">
                  <li>Create, edit, and delete users</li>
                  <li>Set permissions for all roles</li>
                </ul>
              </TableCell>
              <TableCell>
                <ul className="text-gray-500">
                  <li>View and edit</li>
                  <li>View and edit</li>
                </ul>
              </TableCell>
              <TableCell>
                <ul className="text-gray-500">
                  <li>View only</li>
                  <li>View only</li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-medium">
                Hospital profile management
              </TableCell>
              <TableCell className="flex flex-col">
                <ul className="list-disc text-gray-500">
                  <li>Manage care provider profile</li>
                  <li>Upload care provider documents</li>
                  <li>View and sign commercial agreement</li>
                </ul>
              </TableCell>
              <TableCell>
                <ul className="text-gray-500">
                  <li>View and edit</li>
                  <li>View and edit</li>
                  <li>View and edit</li>
                </ul>
              </TableCell>
              <TableCell>
                <ul className="text-gray-500">
                  <li>View and edit</li>
                  <li>View and edit</li>
                  <li>View only</li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-medium">Payment Setup</TableCell>
              <TableCell className="flex flex-col">
                <ul className="list-disc text-gray-500">
                  <li>Ability to set up care provider bank accounts</li>
                </ul>
              </TableCell>
              <TableCell>
                <ul className="text-gray-500">
                  <li>View and edit</li>
                </ul>
              </TableCell>
              <TableCell>
                <ul className="text-gray-500">
                  <li>View only</li>
                </ul>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="font-medium">
                Patient loan application
              </TableCell>
              <TableCell className="flex flex-col">
                <ul className="list-disc text-gray-500">
                  <li>Ability to view patient loan applications.</li>
                  <li>
                    Ability to view, edit & upload patient medical information
                    report
                  </li>
                </ul>
              </TableCell>
              <TableCell>
                <ul className="text-gray-500">
                  <li>View and edit</li>
                  <li>View and edit</li>
                </ul>
              </TableCell>
              <TableCell>
                <ul className="text-gray-500">
                  <li>View and edit</li>
                  <li>View and edit</li>
                </ul>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

function UserManagement() {
  const queryClient = useQueryClient()

  const [dialogOpen, setDialogOpen] = useState(false)
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Inputs>()

  const mutation = useMutation({
    mutationFn: async (data: Inputs) => {
      const result = await axios.post(
        import.meta.env.VITE_API_BASE_URL + "/healthcare/invite-worker",
        data
      )

      return result.data
    },
    onSuccess: (result) => {
      toast({
        title: "Success",
        description: result.message,
      })
      queryClient.invalidateQueries({
        queryKey: [queryKey],
      })
      reset()
      setDialogOpen(false)
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
    },
  })

  return (
    <div>
      <Dialog
        open={dialogOpen}
        onOpenChange={() => {
          setDialogOpen((state) => !state)
        }}
      >
        <div className="flex gap-2  items-center justify-between pb-5 border-b">
          <div className="flex flex-col gap-2 ">
            <Subtitle>Manage your members</Subtitle>
            <Description>
              View and manage all your active members who help you manage your
              process
            </Description>
          </div>

          <ProtectedResource allowedRoles={["OWNER"]}>
            <DialogTriggerButton onClick={() => {}} disabled={false}>
              <Plus className="w-5 h-5" />
              Invite People
            </DialogTriggerButton>
          </ProtectedResource>
        </div>

        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle>Invite People</DialogTitle>
            <DialogDescription>
              Invite people to your care provider portal
            </DialogDescription>
          </DialogHeader>

          <form
            className="grid flex-col gap-5 py-7"
            onSubmit={handleSubmit(async (data) => {
              await mutation.mutateAsync(data)
            })}
          >
            <FormGroupInput
              id="firstName"
              label="First Name"
              type="text"
              placeholder="Enter employee first name"
              register={register("firstName", {
                required: {
                  value: true,
                  message: "Please enter employee first name",
                },
              })}
              error={errors.firstName?.message}
            />
            <FormGroupInput
              id="lastName"
              label="Last Name"
              type="text"
              placeholder="Enter employee last name"
              register={register("lastName", {
                required: {
                  value: true,
                  message: "Please enter employee last name",
                },
              })}
              error={errors.lastName?.message}
            />
            <FormGroupInput
              id="workerEmail"
              label="Email"
              type="email"
              placeholder="Enter employee email address"
              register={register("email", {
                required: {
                  value: true,
                  message: "Please enter employee email address",
                },
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: "Please enter a valid email address",
                },
              })}
              error={errors.email?.message}
            />
            <FormGroupInput
              id="workerTitle"
              label="Role in Care Facility"
              type="text"
              placeholder="Enter employee role e.g CEO, Nurse, Receptionist"
              register={register("title", {
                required: {
                  value: true,
                  message:
                    "Please enter employee role e.g CEO, Nurse, Receptionist",
                },
              })}
              error={errors.title?.message}
            />
            {/* TODO: make this dynamic by pulling allowed roles from the backend */}
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <FormGroupSelect
                  id="workerRole"
                  label="Role"
                  placeholder="Select Role"
                  field={field}
                  error={errors.role?.message}
                  options={[
                    {
                      name: "Admin",
                      value: "ADMIN",
                    },
                  ]}
                />
              )}
            />

            <DialogFooter className="mt-3">
              <Button
                className="w-full"
                size="lg"
                role="link"
                type="submit"
                disabled={mutation.isPending}
                isLoading={mutation.isPending}
              >
                Invite
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <HealthcareUserTable />
    </div>
  )
}

type DialogType = "CLOSED" | "EDIT_ROLE" | "EDIT_TITLE" | "DISABLE_WORKER"

function HealthcareUserTable() {
  const user = useHealthcareUserStore((state: any) => state.user)

  const query = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      const result = await axios.get(
        import.meta.env.VITE_API_BASE_URL + `/healthcare/facility-users`
      )

      return result.data
    },
  })
  const [activeUser, setActiveUser] = useState<any>(null)

  const [dialogType, setDialogType] = useState<DialogType>("CLOSED")

  if (query.isError) {
    const error = query.error as any

    if (error.response?.status === 403) {
      return <ForbiddenBlock />
    }

    return <ErrorBlock />
  }

  if (query.isPending) {
    return <LoadingPage></LoadingPage>
  }

  const { workers } = query.data

  return (
    <Dialog open={dialogType !== "CLOSED"}>
      <Table>
        <TableHeader>
          <TableRow className="bg-gray-100 rounded-md ">
            <TableHead>Name</TableHead>
            <TableHead>Title</TableHead>
            <TableHead>Role</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Date Added</TableHead>
            <TableHead></TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {workers.map((worker: any) => (
            <TableRow key={worker.id}>
              <TableCell className="font-medium flex flex-col gap-1">
                <div className="flex items-center gap-x-6">
                  <div
                    className="rounded-full size-16 w-16 h-16 grid place-content-center text-2xl border-[6px] border-white bg-primary text-white"
                    aria-hidden="true"
                  >
                    {worker.firstName[0]}
                  </div>
                  <div>
                    <h3 className="text-base/7 font-semibold tracking-tight text-gray-900">
                      {worker.firstName} {worker.lastName}
                      {user?.email === worker.email && "(You)"}
                    </h3>
                    <p className="text-sm/6 font-normal">{worker.email}</p>
                  </div>
                </div>
              </TableCell>
              <TableCell>{worker.title}</TableCell>
              <TableCell className="font-medium">{worker.role}</TableCell>
              <TableCell className="">
                {worker.isActive ? "Active" : "Disabled"}
              </TableCell>
              <TableCell>{formatDate(worker.createdAt)}</TableCell>
              <TableCell>
                <ProtectedResource allowedRoles={["OWNER"]}>
                  <Popover>
                    <PopoverTrigger
                      aria-label="Actions"
                      onClick={() => setActiveUser(worker)}
                    >
                      <EllipsisVertical className="w-5 h-5" />
                    </PopoverTrigger>
                    <PopoverContent className="z-50 bg-white rounded-md border border-gray-300 shadow-lg w-32 my-4 flex flex-col ">
                      <DialogTrigger>
                        <PopoverContentButton
                          onClick={() => {
                            setDialogType("EDIT_TITLE")
                            setActiveUser(worker)
                          }}
                        >
                          Edit Title
                        </PopoverContentButton>
                      </DialogTrigger>

                      {worker.role !== "OWNER" && (
                        <>
                          <DialogTrigger>
                            <PopoverContentButton
                              onClick={() => {
                                setDialogType("EDIT_ROLE")
                                setActiveUser(worker)
                              }}
                            >
                              Edit Role
                            </PopoverContentButton>
                          </DialogTrigger>

                          <DialogTrigger>
                            <PopoverContentButton
                              onClick={() => {
                                setDialogType("DISABLE_WORKER")
                                setActiveUser(worker)
                              }}
                            >
                              Disable
                            </PopoverContentButton>
                          </DialogTrigger>
                        </>
                      )}
                    </PopoverContent>
                  </Popover>
                </ProtectedResource>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {activeUser && (
        <ManageUsersDialog
          worker={activeUser}
          setWorker={setActiveUser}
          dialogType={dialogType}
          setDialogType={setDialogType}
        />
      )}
    </Dialog>
  )
}
type UserManagementDialogInputs = {
  workerId: string
  role?: HealthcareWorkerRole
  title?: string
  disableUser?: boolean
}
function ManageUsersDialog({
  worker,
  setWorker,
  dialogType,
  setDialogType,
}: {
  worker: any
  setWorker: (state: any) => void
  dialogType: DialogType
  setDialogType: (state: DialogType) => void
}) {
  const queryClient = useQueryClient()

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<UserManagementDialogInputs>()

  const mutation = useMutation({
    mutationFn: async (data: any) => {
      const resolveEndpoint = () => {
        switch (dialogType) {
          case "EDIT_ROLE":
            return `update-worker-role`
          case "EDIT_TITLE":
            return `update-worker-title`
          case "DISABLE_WORKER":
            return `disable-worker`
        }
      }
      const result = await axios.post(
        import.meta.env.VITE_API_BASE_URL + `/healthcare/${resolveEndpoint()}`,
        {
          ...data,
          workerId: worker.id,
        }
      )

      return result.data
    },
    onSuccess: (result) => {
      toast({
        title: "Success",
        description: result.message,
      })
      setDialogType("CLOSED")
      queryClient.invalidateQueries({
        queryKey: [queryKey],
      })
      queryClient.invalidateQueries({
        queryKey: ["healthcareLoginDetails"],
      })
      setWorker(null)
      reset()
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
    },
  })

  const resolveDialogText = () => {
    switch (dialogType) {
      case "CLOSED":
        return {
          title: "",
          description: "",
          cta: "",
        }
      case "EDIT_TITLE":
        return {
          title: "Edit Title",
          description: "Edit title for " + worker.firstName,
          cta: "Edit Title",
        }
      case "DISABLE_WORKER":
        return {
          title: "Disable User",
          description: "Disable user " + worker.firstName,
          cta: "Disable User",
        }
      case "EDIT_ROLE":
        return {
          title: "Edit Role",
          description: "Edit role for " + worker.firstName,
          cta: "Edit Role",
        }
    }
  }

  return (
    <DialogContent className="sm:max-w-[400px]">
      <DialogHeader>
        <DialogTitle>{resolveDialogText().title}</DialogTitle>
        <DialogDescription>{resolveDialogText().description}</DialogDescription>
      </DialogHeader>

      <form
        className="grid flex-col gap-5 mt-3"
        onSubmit={handleSubmit(async (data) => {
          await mutation.mutate(data)
        })}
      >
        {dialogType === "EDIT_ROLE" && (
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <FormGroupSelect
                id="workerRole"
                label="Role"
                placeholder="Select Role"
                field={field}
                error={errors.role?.message}
                options={[
                  {
                    name: "Admin",
                    value: "ADMIN",
                  },
                ]}
              />
            )}
          />
        )}

        {dialogType === "EDIT_TITLE" && (
          <FormGroupInput
            id="title"
            label="Title"
            type="text"
            placeholder="Enter title"
            register={register("title", {
              required: {
                value: true,
                message: "Please enter title",
              },
            })}
            error={errors.title?.message}
          />
        )}

        {dialogType === "DISABLE_WORKER" && (
          <>
            <input
              type="hidden"
              {...register("disableUser", {
                value: true,
              })}
            />

            <Description>
              Are you sure you want to disable this user? They will lose access
              to <b>All</b> features on the portal.
            </Description>
          </>
        )}
        <DialogFooter>
          <div className="flex flex-col gap-2 w-full mt-2">
            <Button
              className="w-full"
              size="lg"
              role="link"
              type="submit"
              disabled={mutation.isPending}
              isLoading={mutation.isPending}
            >
              {resolveDialogText().cta}
            </Button>
            <Button
              type="button"
              className="w-full"
              variant="outline"
              onClick={() => setDialogType("CLOSED")}
            >
              Cancel
            </Button>
          </div>
        </DialogFooter>
      </form>
    </DialogContent>
  )
}

function PopoverContentButton({
  onClick,
  children,
}: {
  onClick: () => void
  children: React.ReactNode
}) {
  return (
    <div onClick={onClick} className="w-full px-3 py-2 font-medium">
      {children}
    </div>
  )
}
