import AccordionMenu from "@/components/AccordionMenu"
import { WalletCards } from "lucide-react"
import { useState } from "react"
import ArrowButton from "./ArrowButton"
import notes from "@/assets/images/notes.png"
import { formatDate } from "@/utilities/dateUtilities"
import { ScrollArea } from "@radix-ui/react-scroll-area"
import { formatMoney } from "@/utilities/currencyUtilities"
import PaymentPortal from "../Pages/Payment/PatientPaymentPortal"
import { DialogTrigger } from "@/components/Dialog"
import PlusLink from "./PlusLink"
import { usePatientAuthStore } from "../stores/patientAuthStore"
import { resolveNextPayment } from "./YourTreatments"
import DueInTag from "./DueInTag"
import { Link } from "react-router-dom"

export default function UpcomingPayments() {
  const [showPayments, setShowPayments] = useState(true)

  const { loans, membershipStatus } = usePatientAuthStore(
    (state: any) => state.user
  )

  return (
    <AccordionMenu
      title="Upcoming Payments"
      description="Keep your payments on track"
      buttonLabel="view payments"
      icon={<WalletCards className="w-5 h-5 text-gray-500" />}
      active={showPayments}
      buttonDisabled={false}
      onClick={() => setShowPayments(!showPayments)}
    >
      {showPayments && (
        <>
          {loans.length > 0 ? (
            <PaymentItems />
          ) : (
            <div className="mt-7">
              <div className="flex gap-10 items-center">
                <img
                  src={notes}
                  alt="gift box"
                  className="w-16 h-16"
                  aria-hidden={true}
                />

                <div className="flex flex-col gap-5 ">
                  <p className=" text-gray-500 text-sm">
                    All upcoming loan repayments will appear here
                  </p>

                  <ArrowButton disabled={membershipStatus !== "APPROVED"}>
                    <Link
                      to="request-medical-info"
                      className="no-underline w-full h-full grid place-content-center text-white"
                    >
                      Fund New Treatment
                    </Link>
                  </ArrowButton>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </AccordionMenu>
  )
}

function PaymentItems() {
  const { loans } = usePatientAuthStore((state: any) => state.user)

  return (
    <ScrollArea className="max-h-[450px] overflow-y-auto mt-3">
      <ul className="flex flex-col gap-2 mt-5">
        {loans.map((loan: any) => (
          <PaymentItem key={loan.id} loan={loan} />
        ))}
      </ul>
    </ScrollArea>
  )
}

function PaymentItem({ loan }: { loan: any }) {
  const {
    currency,
    id,
    repaymentSchedule,
    totalPaid,
    amount,
    status,
    transactionFeeIsPaid,
  } = loan || {}

  const { treatmentType } = loan.patientMedicalInfoRequest || {}

  const nextPayment = resolveNextPayment(repaymentSchedule.schedule)

  const canBePaid = status === "DISBURSED" && transactionFeeIsPaid

  return (
    <li className="rounded-lg p-4 flex justify-between border text-sm">
      <div className="flex flex-col gap-2 ">
        <p className="font-medium">{treatmentType}</p>
        <p className="text-red-500 font-medium">
          Due {formatDate(nextPayment.dueDate)}
        </p>

        <p className="text-base font-medium">
          {formatMoney(+nextPayment.amount, currency.code)}
        </p>

        <PlusLink link={`/patients/loan-details/${id}`} text="View Details" />
        <PaymentPortal
          loanId={id}
          initialPaymentAmount={+nextPayment.amount}
          maxPayableAmount={+amount - totalPaid}
          title="Loan Repayment"
          description={`Loan repayment for ${treatmentType}. Due on ${formatDate(
            nextPayment.dueDate
          )}`}
        >
          {canBePaid && (
            <DialogTrigger disabled={status !== "DISBURSED"}>
              <ArrowButton asChild disabled={status !== "DISBURSED"}>
                Pay Now
              </ArrowButton>
            </DialogTrigger>
          )}
        </PaymentPortal>
      </div>

      <DueInTag dueDate={nextPayment.dueDate} />
    </li>
  )
}
