import { Button } from "@/components/Button"
import GuarantorAuthTitle from "../components/GuarantorAuthTitle"
import { CreditCard, Landmark, Plus } from "lucide-react"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/Table"
import axios from "axios"
import { useMutation, useQuery } from "@tanstack/react-query"
import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import { useToast } from "@/hooks/useToast"
import {
  Dialog,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
  DialogContent,
  DialogTitle,
} from "@/components/Dialog"
import { useState } from "react"
import { PaymentMethod } from "@/Routes/Patient/Pages/Payment/PatientPaymentPortal"
import { formatMoney } from "@/utilities/currencyUtilities"
import mastercard from "@/assets/icons/mastercard.svg"
import visa from "@/assets/icons/visa.png"
import { formatDate } from "@/utilities/dateUtilities"
import { useNavigate } from "react-router-dom"

export const guarantorPaymentMethodsQueryKey = "getGuarantorPaymentMethods"

export default function GuarantorPaymentMethods() {
  const navigate = useNavigate()

  const query = useQuery({
    queryKey: [guarantorPaymentMethodsQueryKey],
    queryFn: async () => {
      const response = await axios.get(
        `${
          import.meta.env.VITE_SUPERTOKENS_API_DOMAIN
        }/guarantors/payment-methods`
      )

      return response.data
    },
  })

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    return <ErrorBlock />
  }

  const { fee, currency, paymentMethods } = query.data

  return (
    <div className="text-center flex flex-col gap-7 items-center max-w-[800px] mx-auto">
      <GuarantorAuthTitle>Save Payment Details</GuarantorAuthTitle>
      <p className="max-w-[45ch]">
        Save your credit card details to serve as a guarantor for the Jireh
        Health Membership
      </p>

      <p className="w-full text-primary p-5 grid place-content-center bg-yellow-50 font-medium">
        Jireh will always inform you before deducting any payments in connection
        with your guarantorship.
      </p>

      <div className="flex items-center justify-between gap-5 w-full text-left mt-5">
        <div>
          <h2 className="text-lg font-medium">Linked Accounts</h2>
          <p className="text-gray-500">Your added credit cards</p>
        </div>

        <PaymentMethodDialog amount={fee} currency={currency} />
      </div>

      <Table>
        <TableHeader>
          <TableRow className="bg-gray-200 ">
            <TableHead>Account Type</TableHead>
            <TableHead>Bank Name</TableHead>
            <TableHead>Card Number</TableHead>
            <TableHead>Date Added</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody className="text-left">
          {paymentMethods.map((paymentMethod: any) => (
            <TableRow key={paymentMethod.id}>
              <TableCell className="font-medium uppercase flex items-center justify-center gap-1">
                <img
                  src={resolveCardIcon(paymentMethod.cardDetails.cardType)}
                  alt={`${paymentMethod.cardDetails.cardType} icon`}
                  className="w-10"
                />
              </TableCell>
              <TableCell className="capitalize">
                {paymentMethod.cardDetails.bankName}
              </TableCell>
              <TableCell className="capitalize">
                <div className="flex gap-3 font-medium text-gray-600">
                  <div className="flex items-center">
                    {Array.from({ length: 4 }).map((_, i) => (
                      <span key={i}>*</span>
                    ))}
                  </div>
                  <div className="flex items-center">
                    {Array.from({ length: 4 }).map((_, i) => (
                      <span key={i}>*</span>
                    ))}
                  </div>
                  <div className="flex items-center">
                    {Array.from({ length: 4 }).map((_, i) => (
                      <span key={i}>*</span>
                    ))}
                  </div>
                  <div>{paymentMethod.cardDetails.last4Digits}</div>
                </div>
              </TableCell>
              <TableCell className="font-medium">
                {formatDate(paymentMethod.createdAt)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Button
        disabled={paymentMethods.length === 0}
        onClick={() => {
          navigate("/guarantors/onboarding/terms-and-conditions")
        }}
        className="w-full max-w-[400px] mt-5 mx-auto"
      >
        Next
      </Button>
    </div>
  )
}

function PaymentMethodDialog({
  amount,
  currency,
}: {
  amount: number
  currency: string
}) {
  const { toast } = useToast()

  const [activePaymentMethod, setActivePaymentMethod] =
    useState<PaymentMethod>("CREDIT_CARD")

  const mutation = useMutation({
    mutationFn: async () => {
      const response = await axios.post(
        `${
          import.meta.env.VITE_SUPERTOKENS_API_DOMAIN
        }/guarantors/initiate-add-payment-method`,
        {
          paymentMethodType: activePaymentMethod,
        }
      )

      return response.data
    },
    onSuccess: (data: any) => {
      toast({
        title: "Success",
        description: "You will be redirected shortly",
      })

      const { authorizationUrl } = data
      window.location.assign(authorizationUrl)
    },
    onError: (error: any) => {
      toast({
        title: "Error",
        description: error.response?.data?.message || error.message,
        variant: "destructive",
      })
    },
  })
  return (
    <Dialog>
      <DialogTrigger className="flex items-center bg-primary font-medium rounded-lg text-white px-4 py-2">
        <Plus className="w-5 h-5" />
        Add Payment Method
      </DialogTrigger>

      <DialogContent>
        <DialogHeader>
          <DialogTitle>Select a Payment Method</DialogTitle>
          <DialogDescription>
            A fee of {formatMoney(amount, currency)} will be deducted to add
            this payment method. This fee will be refunded shortly after the
            transaction has completed.
          </DialogDescription>
        </DialogHeader>

        <div className="flex flex-col gap-3">
          <PaymentMethod
            title="Credit Card"
            description="Payment will be charged to your card"
            icon={<CreditCard className="w-6 h-6 text-primary" />}
            type="CREDIT_CARD"
            activePaymentMethod={activePaymentMethod}
            setActivePaymentMethod={setActivePaymentMethod}
          />
          <PaymentMethod
            title="Debit Card"
            description="Payment will be charged to your bank account"
            icon={<Landmark className="w-6 h-6 text-primary" />}
            type="DEBIT_CARD"
            activePaymentMethod={activePaymentMethod}
            setActivePaymentMethod={setActivePaymentMethod}
          />
        </div>

        <DialogFooter>
          <Button
            className="w-32"
            onClick={() => {
              mutation.mutate()
            }}
            isLoading={mutation.isPending}
            disabled={mutation.isPending}
          >
            Next
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
function resolveCardIcon(cardType: string) {
  switch (cardType) {
    case "VISA":
      return visa
    case "MASTERCARD":
      return mastercard
    default:
      return visa
  }
}
