import LoadingPage from "@/Routes/LoadingPage"
import ErrorBlock from "@/components/ErrorBlock"
import { SessionAuth } from "supertokens-auth-react/recipe/session"
import { useOnboardingChecklist } from "../hooks/useOnboardingChecklist"
import GuarantorCompleteOnboarding from "../components/GuarantorCompleteOnboarding"
import GuarantorNavbar from "../components/GuarantorNavbar"
import GuarantorSidebar from "../components/GuarantorSidebar"
import { Route, Routes } from "react-router-dom"
import RouteMetadata from "@/components/RouteMetadata"
import GuarantorDashboard from "./GuarantorDashboard"
import GuarantorProfile from "./GuarantorProfile"
export const guarantorLoginDetailsQueryKey = "getGuarantorDetails"

export default function GuarantorHome() {
  const query = useOnboardingChecklist()

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    return <ErrorBlock />
  }

  const { onboardingRedirectLink } = query.data

  return (
    <SessionAuth requireAuth={true}>
      {onboardingRedirectLink ? (
        <GuarantorCompleteOnboarding
          onboardingRedirectLink={onboardingRedirectLink}
        />
      ) : (
        <GuarantorHomeWrapper />
      )}
    </SessionAuth>
  )
}

function GuarantorHomeWrapper() {
  return (
    <div>
      <GuarantorNavbar />
      <div className="mt-16 flex ">
        <GuarantorSidebar />

        <div className="md:ml-64 w-full">
          <Routes>
            <Route
              path="/*"
              element={
                <RouteMetadata title="Dashboard">
                  <GuarantorDashboard />
                </RouteMetadata>
              }
            />
            <Route
              path="/settings/my-profile"
              element={
                <RouteMetadata title="My Profile">
                  <GuarantorProfile />
                </RouteMetadata>
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  )
}
