import { useMutation } from "@tanstack/react-query"
import { CircleHelp, House, LogOut } from "lucide-react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import Session from "supertokens-web-js/recipe/session"
import { useGuarantorUserStore } from "../../Guarantors/stores/guarantorUserStore"

export default function GuarantorSidebar() {
  const user = useGuarantorUserStore((state: any) => state.user)
  const removeUser = useGuarantorUserStore((state: any) => state.removeUser)
  const location = useLocation()
  const navigate = useNavigate()
  const signoutMutation = useMutation({
    mutationFn: async () => {
      await Session.signOut()
      removeUser()
      navigate("/guarantors/auth/login")
    },
  })

  const isActive = (path: string) => {
    if (!path && location.pathname.includes("/guarantors")) {
      return true
    }
    return location.pathname === `/guarantors/${path}`
  }

  return (
    <aside className="w-64 min-h-[90vh] px-5 py-10 overflow-y-auto border-r fixed hidden md:flex left-0 top-16  flex-col h-full">
      <div className=" flex flex-col justify-between h-full gap-7">
        <div>
          <SidebarSection>
            <div className="flex items-center gap-x-6">
              <div
                className="rounded-full min-w-16 min-h-16 grid place-content-center text-2xl border-[6px] border-white bg-primary text-white"
                aria-hidden="true"
              >
                {user?.firstName[0]}
              </div>
              <div>
                <h1 className="text-lg font-medium">
                  {user?.firstName} {user?.lastName}
                </h1>
              </div>
            </div>
          </SidebarSection>

          <SidebarSection className="mt-5">
            <SidebarSubsection title="Affiliates">
              <SidebarLink to="/" isActive={isActive("")}>
                <House className="w-5 h-5" />
                Your Affiliates
              </SidebarLink>
            </SidebarSubsection>

            <SidebarSubsection title="Settings">
              <SidebarLink
                to="settings/my-profile"
                isActive={isActive("settings/my-profile")}
              >
                <img src="/et_tools-2.png" className="w-5 h-5" />
                My Profile
              </SidebarLink>
            </SidebarSubsection>
          </SidebarSection>
        </div>

        <div className="mb-6">
          <SidebarSection className="mt-auto">
            <SidebarSubsection title="">
              <SidebarLink to="mailto:support@jireh-health.com">
                <CircleHelp className="w-5 h-5" />
                Need Help?
              </SidebarLink>
              <SidebarLink to="" onClick={() => signoutMutation.mutate()}>
                <LogOut className="w-5 h-5" />
                Log Out
              </SidebarLink>
            </SidebarSubsection>
          </SidebarSection>
        </div>
      </div>
    </aside>
  )
}

function SidebarSection({
  children,
  className = "",
}: {
  children: React.ReactNode
  className?: string
}) {
  return (
    <li className={`flex flex-col gap-7 border-b pb-4 ${className}`}>
      {children}
    </li>
  )
}

function SidebarSubsection({
  title,
  children,
}: {
  title: string
  children: React.ReactNode
}) {
  return (
    <div className="flex flex-col gap-1">
      {title && (
        <h2 className="font-medium text-gray-500 uppercase text-sm">{title}</h2>
      )}
      <ul className="flex flex-col gap-2 mt-1">{children}</ul>
    </div>
  )
}

function SidebarLink({
  to,
  onClick,
  children,
  isActive,
}: {
  to: string
  children: React.ReactNode
  onClick?: () => void
  isActive?: boolean
}) {
  return (
    <Link
      to={to}
      className={`flex gap-2 items-center px-2 py-2 rounded-md text-gray-700 no-underline hover:text-primary hover:bg-gray-100 ${
        isActive ? "bg-gray-100 border-l-4 border-purple-600 text-black" : ""
      }`}
      onClick={onClick}
    >
      {children}
    </Link>
  )
}
