import ErrorBlock from "@/components/ErrorBlock"
import RouteMetadata from "@/components/RouteMetadata"
import LoadingPage from "@/Routes/LoadingPage"
import { Route, Routes } from "react-router-dom"
import { SessionAuth } from "supertokens-auth-react/recipe/session"
import { useOnboardingChecklist } from "../hooks/useOnboardingChecklist"
import PatientEmploymentDetails from "../Pages/PatientEmploymentDetails"
import PatientFinancialStatements from "../Pages/PatientFinancialStatements"
import PatientGuarantorInformation from "../Pages/PatientGuarantorInformation"
import PatientVerifyId from "../Pages/PatientVerifyId"
import PatientVerifyWhatsapp from "../Pages/PatientVerifyWhatsapp"
import ViewLoanDetails from "./Loans/PatientViewLoanDetails"
import PatientDashboard from "./PatientDashboard"
import PatientLinkSocials from "./PatientLinkSocials"
import PatientRequestMedicalInfo from "./PatientRequestMedicalInfo"
import PatientTermsAndConditions from "./PatientTermsAndConditions"
import InitiatePayment from "./Payment/PatientInitiatePayment"
import TransactionResult from "./Payment/PatientTransactionResult"
import PayTransactionFee from "./PatientPayTransactionFee"

// import { useNotificationPermission } from "@/hooks/useRequestNotificationAccess"

export const patientLoginDetailsQueryKey = "patientLoginDetails"
export default function PatientsHome() {
  const query = useOnboardingChecklist()

  if (query.isLoading) {
    return <LoadingPage />
  }

  if (query.isError) {
    const error: any = query.error
    return <ErrorBlock message={error.response?.data.message} />
  }

  return (
    <SessionAuth requireAuth={true}>
      <div className="px-5 sm:px-10 py-7 sm:mt-10 flex flex-col gap-7 mx-auto  max-w-[450px] sm:border sm:border-input">
        <Routes>
          <Route
            path="/"
            element={
              <RouteMetadata title="Dashboard">
                <PatientDashboard />
              </RouteMetadata>
            }
          />

          {/* Loans */}
          <Route
            path="/loan-details/:id"
            element={
              <RouteMetadata title="Loan Details">
                <ViewLoanDetails />
              </RouteMetadata>
            }
          />
          <Route
            path="/loans/pay-transaction-fee"
            element={
              <RouteMetadata title="Pay Transaction Fee">
                <PayTransactionFee />
              </RouteMetadata>
            }
          />

          {/* Payment */}
          <Route
            path="/initiate-payment"
            element={
              <RouteMetadata title="Initiate Payment">
                <InitiatePayment />
              </RouteMetadata>
            }
          />
          <Route
            path="/transaction-result"
            element={
              <RouteMetadata title="Transaction Result">
                <TransactionResult />
              </RouteMetadata>
            }
          />

          {/* Onboarding */}

          <Route
            path="/transaction-result"
            element={
              <RouteMetadata title="Transaction Result">
                <TransactionResult />
              </RouteMetadata>
            }
          />

          {/* Onboarding */}
          <Route
            path="/verify-whatsapp"
            element={
              <RouteMetadata title="Verify Whatsapp">
                <PatientVerifyWhatsapp />
              </RouteMetadata>
            }
          />
          <Route
            path="/verify-id"
            element={
              <RouteMetadata title="Verify ID">
                <PatientVerifyId />
              </RouteMetadata>
            }
          />
          <Route
            path="/employment-details"
            element={
              <RouteMetadata title="Employment Details">
                <PatientEmploymentDetails />
              </RouteMetadata>
            }
          />
          <Route
            path="/financial-statements"
            element={
              <RouteMetadata title="Financial Statements">
                <PatientFinancialStatements />
              </RouteMetadata>
            }
          />
          <Route
            path="/guarantor-information"
            element={
              <RouteMetadata title="Guarantor Information">
                <PatientGuarantorInformation />
              </RouteMetadata>
            }
          />
          <Route
            path="/link-socials/*"
            element={
              <RouteMetadata title="Link Socials">
                <PatientLinkSocials />
              </RouteMetadata>
            }
          />
          <Route
            path="/terms-and-conditions"
            element={
              <RouteMetadata title="Terms and COnditions">
                <PatientTermsAndConditions />
              </RouteMetadata>
            }
          />

          <Route
            path="/request-medical-info"
            element={
              <RouteMetadata title="Medical Information">
                <PatientRequestMedicalInfo />
              </RouteMetadata>
            }
          />
        </Routes>
      </div>
    </SessionAuth>
  )
}
