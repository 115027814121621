import SplashScreenProgressBar from "@/Routes/SplashScreenProgressBar"
export default function ComplianceSplashScreen() {
  return (
    <div
      className="h-screen flex flex-col "
      style={{ backgroundImage: `url("background-gradient.png")` }}
    >
      <div
        className="h-screen flex flex-col relative overflow-hidden"
        style={{
          backgroundImage: `url("background-contours.png")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div className="flex items-center justify-between px-6 pt-4">
          <img src="/jireh-white.svg" alt="Jireh Logo" className="h-8" />
          <SplashScreenProgressBar currentStep={5} />
        </div>

        <div className="flex flex-col items-center justify-center flex-grow px-6 text-center">
          <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold text-white mb-6">
            We take your protection very seriously
          </h1>

          <h2 className="text-lg text-white opacity-75 mb-4">
            We are compliant with:
          </h2>
          <div className=" flex items-center justify-center mb-8">
            <img
              src="/kenya-court-of-arms.png"
              alt="Court of Arms"
              className="max-h-36"
            />
          </div>

          <div className="pt-4 text-white">
            <h3 className="text-lg mb-2">Our team's worked at:</h3>
            <div className="flex items-center justify-center mb-8">
              <img
                src="/world-bank.png"
                alt="World Bank Logo"
                className=" max-h-36 mx-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
