import { useEffect } from "react"
import Session from "supertokens-web-js/recipe/session"
import * as amplitude from "@amplitude/analytics-browser"

export default function useSetAmplitudeUserId() {
  return useEffect(() => {
    async function setAmplitudeUserId() {
      if (await Session.doesSessionExist()) {
        let userId = await Session.getUserId()
        amplitude.setUserId(userId)
      }
    }

    setAmplitudeUserId()
  }, [])
}
