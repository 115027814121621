import { useNavigate } from "react-router-dom"
import GuarantorAuthTitle from "./GuarantorAuthTitle"
import { Button } from "@/components/Button"

export default function GuarantorCompleteOnboarding({
  onboardingRedirectLink,
}: {
  onboardingRedirectLink: string
}) {
  const navigate = useNavigate()

  return (
    <div className="flex flex-col max-w-[400px] mx-auto gap-7 text-center">
      <GuarantorAuthTitle>Complete Sign Up</GuarantorAuthTitle>

      <p>
        It looks like you haven't completed your sign up yet. Please complete
        your sign up to continue.
      </p>

      <Button
        role="link"
        onClick={() => navigate(onboardingRedirectLink || "/guarantors")}
      >
        Complete Sign Up
      </Button>
    </div>
  )
}
