import { Route, Routes, useNavigate } from "react-router-dom"
import BasicDetailsForm from "../components/auth/HealthcareSignUpBasicDetails"
import VerifyEmailForm from "../components/auth/HealthcareSignUpVerifyEmail"
import VerifyIdForm from "../components/auth/HealthcareSignUpVerifyId"
import UploadDocumentsForm from "../components/auth/HealthcareSignUpUploadDocuments"
import RouteMetadata from "@/components/RouteMetadata"
import HealthcareCompleteSignUp from "./HealthcareCompleteSignup"

export default function HealthcareSignUp() {
  const navigate = useNavigate()

  return (
    <Routes>
      <Route
        path="/"
        element={
          <RouteMetadata title="Healthcare Sign Up">
            <BasicDetailsForm navigate={navigate} />
          </RouteMetadata>
        }
      />
      <Route
        path="/verify-email"
        element={
          <RouteMetadata title="Verify Email">
            <VerifyEmailForm navigate={navigate} />
          </RouteMetadata>
        }
      />
      <Route
        path="/verify-id"
        element={
          <RouteMetadata title="Verify Id">
            <VerifyIdForm navigate={navigate} />
          </RouteMetadata>
        }
      />
      <Route
        path="/upload-documents"
        element={
          <RouteMetadata title="Upload Documents">
            <UploadDocumentsForm navigate={navigate} />
          </RouteMetadata>
        }
      />

      <Route path="/complete-signup" element={<HealthcareCompleteSignUp />} />
    </Routes>
  )
}
